import axios from "axios";
import { serverEndpoint } from "../../util/config";
import { useEffect, useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { DEFAULT_PAGE_SIZE } from "../../util/constants";
import { getMonthYear } from "../../util/utilityFunctions";
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { format } from 'date-fns';
import { Link } from "react-router-dom";
import { FaFileExcel } from "react-icons/fa";

function ViewCompletedTasksV2() {
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState(null);
    const [tasksData, setTasksData] = useState([]);
    const [displayData, setDisplayData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [paginationKey, setPaginationKey] = useState(null);
    const [itemsLoading, setItemsLoading] = useState(false);
    const [localDataAvailable, setLocalDataAvailable] = useState(false);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
    const [currentMonthYear, setCurrentMonthYear] = useState(convertToYearMonth(getMonthYear()));
    const [formData, setFormData] = useState({
        monthYear: currentMonthYear
    });
    const [selectedItems, setSelectedItems] = useState([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [isExcelReportInProgress, setIsExcelReportInProgress] = useState(false);
    
    const [showExcelHeaderFieldModel, setShowExcelHeaderFieldModel] = useState(false);
    const [excelFormData, setExcelFormData] = useState({
        prepOwner: 'Seller',
        labelingOwner: 'Seller'
    });

    const handleExcelHeaderFieldClose = () => setShowExcelHeaderFieldModel(false);
    const handleExcelHeaderModalShow = () => {
        setShowExcelHeaderFieldModel(true);
    }

    const handleExcelHeaderFieldChange = (e) => {
        const { name, value } = e.target;
        setExcelFormData({
            ...excelFormData,
            [name]: value,
        });
    };

    const getTasks = async (reset = false) => {
        if (!reset) {
            if (itemsLoading || localDataAvailable) return;
        }

        try {
            const monthYear = convertToMonthYear(formData.monthYear);
            setItemsLoading(true);
            const params = {
                limit: DEFAULT_PAGE_SIZE,
                month_year: monthYear
            };

            if (!reset && paginationKey) {
                params.lastEvaluatedKey = paginationKey;
            }

            const response = await axios.get(`${serverEndpoint}/tasks/get-tasks/COMPLETED`, {
                params: params,
                withCredentials: true,
            });
            setTasksData(!reset ? (prev) => [...prev, ...response.data.items] : response.data.items);
            setDisplayData(!reset ? (prev) => [...prev, ...response.data.items] : response.data.items);
            setPaginationKey(response.data.lastEvaluatedKey);
            if (!response.data.lastEvaluatedKey) {
                setLocalDataAvailable(true);
            } else {
                setLocalDataAvailable(false);
            }
            setCurrentMonthYear(formData.monthYear);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setErrors({ fatal: 'Something went wrong, please try again!' });
            setLoading(false);
        } finally {
            setItemsLoading(false);
        }
    };

    useEffect(() => {
        getTasks();
    }, [page]);

    const getSearchResults = async () => {
        try {
            setItemsLoading(true);
            const params = {
                limit: DEFAULT_PAGE_SIZE,
                searchString: searchQuery
            };
            if (paginationKey) {
                params.lastEvaluatedKey = paginationKey;
            }

            const response = await axios.get(`${serverEndpoint}/tasks/get-tasks/COMPLETED`, {
                params: params,
                withCredentials: true,
            });
            setDisplayData(response.data.items);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setErrors({ fatal: 'Something went wrong, please try again!' });
            setLoading(false);
        } finally {
            setItemsLoading(false);
        }
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchQuery.trim() === "") {
                setDisplayData([...tasksData]); // Reset to full data if search term is empty
            } else {
                const searchResults = paginationKey === null
                    ? tasksData.filter((item) =>
                        item.title?.toLowerCase().includes(searchQuery.toLowerCase())
                    ) // Local search
                    : null;

                if (searchResults !== null) {
                    setDisplayData(searchResults);
                } else {
                    getSearchResults();
                }
            }
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [searchQuery, tasksData]);

    const handleMonthChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.monthYear === currentMonthYear) return;
        await getTasks(true);
    };

    const theme = createTheme({
        components: {
            MuiDataGrid: {
                styleOverrides: {
                    root: {
                        border: 'none',
                        overflowY: 'hidden',
                    },
                    columnHeaders: {
                        fontWeight: 'bold', // Apply bold to the entire header row container
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontWeight: 'bold', // Ensure individual header text is bold
                        },
                    },
                },
            },
        },
    });
    const handleCheckboxChange = (row, isChecked) => {
        const updatedRow = displayData.find((item) => item?.sk === row?.sk);
        if (isChecked) {
            const newItem = {
                sk: updatedRow.sk,
                sku: updatedRow.sku ? updatedRow.sku : 'Not Available',
                quantity: updatedRow?.quantity_to_prepare?.toString(),
            };

            setSelectedItems((prevSelectedItems) => {
                const updatedSelectedItems = [...prevSelectedItems, newItem];
                setIsButtonDisabled(updatedSelectedItems.length === 0);
                return updatedSelectedItems;
            });
        } else {
            setSelectedItems((prevSelectedItems) => {
                const updatedSelectedItems = prevSelectedItems.filter(
                    (item) => item.sk !== updatedRow.sk
                );
                setIsButtonDisabled(updatedSelectedItems.length === 0);
                return updatedSelectedItems;
            });
        }
    };

    const handleGenerateExcel = async (e) => {
        e.preventDefault();
        setMessage(null);
        handleExcelHeaderFieldClose();
        if (selectedItems.length === 0) {
            setErrors({ message: 'Please select at least one item to proceed!' });
            return;
        }

        const payload = {
            prep_owner: excelFormData.prepOwner, // Hardcoded for now
            labeling_owner: excelFormData.labelingOwner, // Hardcoded for now
            excelData: selectedItems.map((item) => ({
                sku: item.sku,
                quantity: item.quantity,
            })),
        };

        try {
            setIsExcelReportInProgress(true);
            const response = await axios.post(`${serverEndpoint}/async/generate-excelsheet`, payload, { withCredentials: true });
            if (response.status === 200) {
                setMessage(
                    <>
                        Your export request has been submitted successfully!<br />
                        Request ID: <strong>{response.data.data.uuid}. </strong>
                        You can track the progress of your export{" "}
                        <Link to={`/reports`}>here</Link>.
                    </>
                );
            } else {
                setErrors({ message: 'Failed to generate excel report, please try again' });
            }
        } catch (error) {
            console.error('Error generating Excel sheet:', error);
            setErrors({ message: 'Failed to generate excel report, please try again' });
        } finally {
            setIsExcelReportInProgress(false);
        }
    };



    const columns = [
        { field: 'sk', headerName: 'Item ID', width: 300 },
        {
            field: 'select',
            headerName: 'Select',
            width: 80,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                const isChecked = selectedItems.some(
                    (item) => item.sk === params.row.sk
                );

                return (
                    <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={(event) => handleCheckboxChange(params.row, event.target.checked)}
                        style={{
                            width: '16px',
                            height: '16px',
                            cursor: 'pointer',
                        }}
                    />
                );
            },
        },
        {
            field: 'title',
            headerName: 'Title',
            width: 350,
            editable: false,
            renderCell: (params) => {
                const splitedSk = params.row.item_id.split('#');
                let url;
                if (splitedSk[0] === 'B') {
                    url = `/bundle/${splitedSk[1]}`;
                } else if (splitedSk[0] === 'L') {
                    url = `/listing/${splitedSk[1]}`;
                } else if (splitedSk[0] === 'C') {
                    url = `/component/${splitedSk[1]}`;
                } else {
                    url = '#';
                }

                return (
                    <>
                        <Link className="mx-1" to={url}>
                            <img src={params.row.image_url ? params.row.image_url : '/images/default-image.jpg'}
                                onError={(e) => {
                                    e.target.onerror = null; // Prevent infinite loop if fallback image fails
                                    e.target.src = "/images/default-image.jpg";
                                }}
                                alt={params.row.title} className="rounded-1" style={{
                                    maxHeight: '30px'
                                }} />
                        </Link>
                        <Link className="mx-1 text-body text-decoration-none hover:text-primary" to={url}>{params.row.title}</Link>
                    </>
                );
            }
        },
        {
            field: 'creation_date',
            headerName: 'Creation Date',
            width: 130,
            editable: false,
            renderCell: (params) => (
                <div>
                    {format(new Date(params.row.creation_date), 'MMM d, yyyy')}
                </div>
            )
        },
        {
            field: 'completion_date',
            headerName: 'Completion Date',
            width: 150,
            editable: false,
            renderCell: (params) => (
                <div>
                    {format(new Date(params.row.completion_date), 'MMM d, yyyy')}
                </div>
            )
        },
        {
            field: 'quantity_to_prepare',
            headerName: 'Qty Prepared',
            width: 130,
            editable: false
        }
    ];

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    return (
        <section>
            <div className="container bg-white p-4 rounded border">
                <div className="row align-items-center">
                    {errors.message && (
                        <div className="alert alert-danger" role="alert">
                            {errors.message}
                        </div>
                    )}
                    {message && (
                        <div className="alert alert-success" role="alert">
                            {message}
                        </div>
                    )}
                    {/* Left Section: Title and Subtitle */}
                    <div className="col">
                        <h2 className="mb-0">Completed Tasks</h2>
                        <p className="text-secondary mb-0">View completed tasks by month</p>
                    </div>

                    {/* Right Section: Button */}
                    <div className="col-auto">
                        {isExcelReportInProgress && (
                            <button className="btn btn-success text-white ms-auto btn-sm d-block d-md-inline" type="button" disabled="">
                                <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </button>
                        )}

                        {!isExcelReportInProgress && (
                            <button className="btn btn-success text-white ms-auto btn-sm d-block d-md-inline" type="submit" onClick={handleExcelHeaderModalShow} disabled={isButtonDisabled}>
                                <FaFileExcel className="mb-1" /> Export
                            </button>
                        )}
                    </div>
                </div>
                <hr className="text-muted" />
                <div className="my-3 text-center">
                    <div className="mb-3 form-group">
                        <input
                            type="month"
                            className="form-control form-control-sm"
                            id="monthYear"
                            name="monthYear"
                            defaultValue={formData.monthYear}
                            onChange={handleMonthChange}
                            max={convertToYearMonth(getMonthYear())}
                        />
                    </div>
                    {itemsLoading && (
                        <button className="btn btn-primary btn-sm ms-auto" type="button" disabled="">
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </button>
                    )}

                    {!itemsLoading && (
                        <button className="btn btn-primary btn-sm ms-auto" type="button" onClick={handleSubmit}>
                            Search
                        </button>
                    )}
                </div>

                <hr className="text-muted" />
                <div className="my-3 text-center">
                    <Form.Control
                        type="text"
                        placeholder="Search by title or Description"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <ThemeProvider theme={theme}>
                        <Box>
                            <DataGrid
                                initialState={{
                                    columns: {
                                        columnVisibilityModel: {
                                            sk: false
                                        }
                                    },
                                    pagination: {
                                        paginationModel: { page: 0, pageSize: DEFAULT_PAGE_SIZE }, // Set initial state explicitly
                                    },
                                }}
                                rows={displayData.slice(page * pageSize, (page + 1) * pageSize)}
                                getRowId={(row) => row.sk}
                                rowCount={localDataAvailable ? displayData.length : -1}
                                columns={columns}
                                disableRowSelectionOnClick
                                showCellVerticalBorder={true}
                                showColumnVerticalBorder={true}
                                pagination
                                paginationMode="server"
                                pageSize={pageSize}
                                page={page} // Controlled page
                                rowsPerPageOptions={[DEFAULT_PAGE_SIZE]}
                                onPaginationModelChange={({ page }) => {
                                    handlePageChange(page);
                                }}
                                loading={loading || itemsLoading}
                                sx={{
                                    minHeight: displayData.length === 0 ? 400 : 'auto',
                                    '& .MuiDataGrid-overlay': {
                                        backgroundColor: '#ffffff',
                                    },
                                }}
                                localeText={{
                                    noRowsLabel: "No data available to display",
                                }}
                            />
                        </Box>
                    </ThemeProvider>
                </div>
            </div>
            <Modal show={showExcelHeaderFieldModel} onHide={handleExcelHeaderFieldClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Select Prep Owner and Labeling Owner</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleGenerateExcel}>
                        <div className="mb-3">
                            <label className="mb-2 text-muted" htmlFor="prepOwner">
                                Prep Owner
                            </label>
                            <select className="form-control form-control-sm" id="prepOwner" name="prepOwner"
                                value={excelFormData.prepOwner} onChange={handleExcelHeaderFieldChange}
                            >
                                <option value='Seller'>Seller</option>
                                <option value='Amazon'>Amazon</option>
                            </select>
                        </div>

                        <div className="mb-3">
                            <label className="mb-2 text-muted" htmlFor="labelingOwner">
                                Labeling Owner
                            </label>
                            <select className="form-control form-control-sm" id="labelingOwner" name="labelingOwner"
                                value={excelFormData.labelingOwner} onChange={handleExcelHeaderFieldChange}
                            >
                                <option value='Seller'>Seller</option>
                                <option value='Amazon'>Amazon</option>
                            </select>
                        </div>

                        <div className="align-items-center d-flex">
                            <button className="btn btn-primary btn-sm ms-auto" type="submit">
                                Submit
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </section>
    );
}

const convertToYearMonth = (dateStr) => {
    const month = dateStr.split('-')[0];
    const year = dateStr.split('-')[1];
    return `${year}-${month}`;
}

const convertToMonthYear = (dateStr) => {
    const year = dateStr.split('-')[0];
    const month = dateStr.split('-')[1];
    return `${month}-${year}`;
}

export default ViewCompletedTasksV2;