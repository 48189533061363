import React, { useState, useEffect } from 'react';
import axios from "axios";
import { serverEndpoint } from "../../../util/config";
import Spinner from "../../../components/Spinner";
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import { CButton, CCard, CCardBody, CCardText, CCardTitle, CRow, CCol, CModal, CModalHeader, CModalBody, CModalFooter, CModalTitle, CCardSubtitle } from '@coreui/react';
import { Badge } from 'react-bootstrap';
import { FaCircleCheck } from "react-icons/fa6";
import { FaCloudDownloadAlt } from "react-icons/fa";

const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
};

const getPlan = async (customerID) => {
    try {
        const response = await axios.post(`${serverEndpoint}/payment/check-subscription`,
            { stripe_customer_id: customerID },
            { withCredentials: true });
        const { subscription, upcoming_invoice } = response.data;

        
        return {
            subscriptionId: subscription.subscriptionId,
            planTitle: subscription.productName,
            price: parseFloat(subscription.price / 100).toFixed(2),
            discountPrice: parseFloat(subscription.discountPrice / 100).toFixed(2),
            timeline: subscription.timeline,
            endDate: formatDate(subscription.endDate * 1000),
            status: subscription.status,
            hostedInvoiceUrl: subscription.hostedInvoiceUrl,
            upcomingPrice: parseFloat(upcoming_invoice.price / 100).toFixed(2),
            upcomingDiscountPrice: parseFloat(upcoming_invoice.discountPrice / 100).toFixed(2),
        };
    } catch (error) {
        console.log(error);
        return null;
    }
};

const CancelSubscriptionBox = ({ email, customerID, plan }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleCancelClick = () => {
        setShowConfirmation(true);
    };

    const handleConfirmCancel = () => {
        setLoading(true);
        axios.post(`${serverEndpoint}/payment/cancel-subscription`,
            { email: email, customerID: customerID, subscriptionId: plan.subscriptionId },
            { withCredentials: true })
            .then(() => {
                // After successful subscription cancellation, delete the account
                return axios.post(`${serverEndpoint}/auth/delete-account`, { email: email }, { withCredentials: true });
            })
            .then(() => {
                dispatch({ type: 'toggleFetchTrigger' });
                navigate('/logout');
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                // Ensure loading is reset once all requests are done
                setLoading(false);
            });
        setShowConfirmation(false);
    };

    const handleCancelConfirmation = () => {
        setShowConfirmation(false);
    };

    return (
        <CCard>
            {loading && (
                <button className="btn btn-danger ms-auto" type="button" disabled="">
                    <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    />
                </button>
            )}

            {!loading && (
                <button className="btn btn-danger text-white btn-sm" onClick={handleCancelClick}
                    disabled={loading}>
                    Cancel Subscription
                </button>
            )}
            <CModal visible={showConfirmation} onClose={handleCancelConfirmation}>
                <CModalHeader closeButton>
                    <CModalTitle>Confirm Cancellation</CModalTitle>

                </CModalHeader>
                <CModalBody>
                    Are you sure you want to cancel your subscription? Please note that your account (including all your child accounts) will be deleted.
                </CModalBody>
                <CModalFooter>
                    <CButton color="secondary" onClick={handleCancelConfirmation} disabled={loading}>
                        No
                    </CButton>
                    <CButton color="primary" onClick={handleConfirmCancel} disabled={loading}>
                        Yes
                    </CButton>
                </CModalFooter>
            </CModal>
        </CCard>
    );
};

const formatUnixTimestamp = (unixTimestampString) => {
    const unixTimestamp = Number(unixTimestampString); // Convert string to number
    const date = new Date(unixTimestamp); // Convert seconds to milliseconds
    return date.toLocaleDateString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
    });
}

function ManageSubscription({ userDetails }) {
    const [plan, setPlan] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/change-subscription");
    };

    useEffect(() => {
        const fetchPlan = async () => {
            const planData = await getPlan(userDetails.stripeObject?.customer_id);
            setPlan(planData);
            setLoading(false);
        };

        if (userDetails.hasTrialEnded) {
            fetchPlan();
        } else {
            setLoading(false);
        }
    }, [userDetails.username]);

    if (loading) return <Spinner />;

    return (
        <CCard>
            <CCardBody>
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        <CCardTitle>Plan & Billing</CCardTitle>
                        <CCardText>Manage your plan and payments</CCardText>
                    </div>
                    <div>
                        {plan && (
                            <CancelSubscriptionBox
                                plan={plan}
                                email={userDetails.username}
                                customerID={userDetails.stripeObject?.customer_id}
                            />
                        )}
                    </div>
                </div>
            </CCardBody>
            <hr className='my-0'></hr>

            <CCardBody>
                {plan && (
                    <>
                        {plan.status === 'trialing' && (
                            <div className="alert alert-info" role="alert">
                                You are on a 14-day free trial ending <strong>{plan.endDate}</strong>
                                . We hope you use the trial period to explore how Prep Master can save you time and boost productivity.
                            </div>
                        )}
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <div>
                                <CCardSubtitle>Current Plan</CCardSubtitle>
                            </div>
                            <div>
                                <CCard>
                                    <button className='btn btn-primary btn-sm' onClick={handleClick}>
                                        Change Plan
                                    </button>
                                </CCard>
                            </div>
                        </div>
                        <div>
                            <CRow>
                                <CCol xs={12} sm={6} className='mt-2'>
                                    <CCard>
                                        <CCardBody>
                                            <CCardText className="d-flex justify-content-between align-items-center">
                                                {plan.timeline === 'month' ? 'Monthly' : 'Yearly'}
                                                <Badge pill bg="success" className="text-white">
                                                    <FaCircleCheck className='pb-0' /> Active
                                                </Badge>
                                            </CCardText>
                                            <CCardTitle>
                                                {plan.planTitle}
                                            </CCardTitle>
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                                <CCol xs={12} sm={6} className='mt-2'>
                                    <CCard>
                                        <CCardBody>
                                            <CCardText className="d-flex justify-content-between align-items-center">
                                                Last Payment
                                                <Badge pill bg="primary" className="text-white">
                                                    <Link to={plan.hostedInvoiceUrl} target="_blank" className="text-white text-decoration-none hover:text-primary">
                                                        <FaCloudDownloadAlt className='pb-0' /> Invoice
                                                    </Link>
                                                </Badge>
                                            </CCardText>
                                            <CCardTitle>
                                                {plan.discountPrice !== plan.price ? (
                                                    <>
                                                        <small style={{ fontSize: '0.8em', color: 'gray' }}>
                                                            <del>£{plan.price}</del>
                                                        </small>
                                                        &nbsp;
                                                        <strong>£{plan.discountPrice}</strong>
                                                    </>
                                                ) : (
                                                    <>
                                                        £{plan.price}
                                                    </>
                                                )}
                                            </CCardTitle>
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                            </CRow>
                            <div className='mt-5 mb-3'>
                                <CCardSubtitle>Upcoming Billing Information</CCardSubtitle>
                            </div>
                            <CRow className='mt-2'>
                                <CCol xs={12} sm={6} className='mt-2'>
                                    <CCard>
                                        <CCardBody>
                                            <CCardText className="d-flex justify-content-between align-items-center">
                                                Next Billing Amount
                                            </CCardText>
                                            <CCardTitle>
                                                {plan.upcomingDiscountPrice !== plan.upcomingPrice ? (
                                                    <>
                                                        <small style={{ fontSize: '0.8em', color: 'gray' }}>
                                                            <del>£{plan.upcomingPrice}</del>
                                                        </small>
                                                        &nbsp;
                                                        <strong>£{plan.upcomingDiscountPrice}</strong>
                                                    </>
                                                ) : (
                                                    <>
                                                        £{plan.upcomingPrice}
                                                    </>
                                                )}
                                            </CCardTitle>
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                                <CCol xs={12} sm={6} className='mt-2'>
                                    <CCard>
                                        <CCardBody>
                                            <CCardText>Next Billing Date</CCardText>
                                            <CCardTitle>
                                                {plan.endDate}
                                            </CCardTitle>
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                            </CRow>
                        </div>
                    </>
                )}

                {!userDetails.hasTrialEnded && (
                    <div className=''>
                        <CCardTitle>You are on a 14-day free trial ending <strong className='text-primary'>{formatUnixTimestamp(userDetails.traildEndDate)}</strong></CCardTitle>
                        <p>
                            We hope you use the trial period to explore how Prep Master can save you time and boost productivity. After the trial, you'll need to subscribe to continue using Prep Master.
                        </p>
                    </div>
                )}
            </CCardBody>
        </CCard>
    );
}

export default ManageSubscription;
