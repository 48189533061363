import React from 'react';
import AppHeader from '../components/AppHeader';
import AppSidebar from '../components/AppSidebar';
import AppFooter from '../components/AppFooter';
import { CContainer } from '@coreui/react';

function AppLayout({ children, ...props }) {
    return (
        <div>
            <AppSidebar />
            <div className="wrapper d-flex flex-column min-vh-100">
                <AppHeader {...props} />
                <div className="body flex-grow-1">
                    <CContainer className="px-4" lg>
                        {children}
                    </CContainer>
                </div>
                <AppFooter />
            </div>
        </div>
    );
};

export default AppLayout;
