import { useEffect, useState } from "react";
import Spinner from "../../components/Spinner";
import { serverEndpoint, CURRENCY_TO_SYMBOL_MAP } from "../../util/config";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Card, Col, Row } from "react-bootstrap";
import { MdModeEdit, MdDelete } from "react-icons/md";
import ConfirmDialog from "../../components/ConfirmDialog";
import ErrorComponent from "../../components/Error";
import { useSelector } from 'react-redux';
import { hasPermission } from "../../util/permissionsEvaluator";

function ViewListing() {
    const userDetails = useSelector((state) => state.userDetails);
    const currencySymbol = CURRENCY_TO_SYMBOL_MAP[userDetails.accountCurrency] || '£';
    const navigate = useNavigate();
    const { listingId } = useParams();
    const [loading, setLoading] = useState(true);
    const [formLoading, setFormLoading] = useState(false);
    const [listingData, setListingData] = useState(null);

    const [isEdit, setIsEdit] = useState(false);
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});

    const handleDelete = () => {
        if (!hasPermission(userDetails, "Amazon Listing", "Delete")) {
            setErrors({ message: 'You do not have permission to perform this action.' });
            return;
        }
        setShowConfirmDeleteModal(true);
    };

    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const handleConfirmDeleteModalClose = (confirmed) => {
        if (confirmed) {
            setLoading(true);
            axios.post(`${serverEndpoint}/listings/delete-listing`, {
                listing_id: listingId,
                image_key: listingData.image_key,
                instock_quantity: listingData.instock_quantity,
                cog: listingData.cog
            }, { withCredentials: true })
                .then(response => {
                    setShowConfirmDeleteModal(false);
                    setFormLoading(false);
                    navigate('/listings');
                }).catch(error => {
                    console.log(error);
                    setErrors({ message: "Unable to delete listing, try again!" });
                    setShowConfirmDeleteModal(false);
                    setFormLoading(false);
                });
        } else {
            setShowConfirmDeleteModal(false);
            setFormLoading(false);
        }
    };

    const handleEdit = () => {
        if (!hasPermission(userDetails, "Amazon Listing", "Edit")) {
            setErrors({ message: 'You do not have permission to perform this action.' });
            return;
        }
        setFormData(listingData);
        setIsEdit(true);
    };

    const updateListingData = (newListingData, isUpdated) => {
        if (isUpdated) {
            setListingData(newListingData);
        }

        setIsEdit(false);
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        if (formData.instock_quantity === undefined || formData.instock_quantity === '') {
            newErrors.instock_quantity = "In-stock quantity is required.";
            isValid = false;
        } else if (!Number.isInteger(Number(formData.instock_quantity)) || Number(formData.instock_quantity) < 0) {
            newErrors.instock_quantity = "In-stock quantity must be a non-negative integer.";
            isValid = false;
        }

        const floatFields = ['sale_price', 'cog', 'margin', 'roi', 'profit'];

        floatFields.forEach((field) => {
            if (formData[field] === undefined || formData[field] === '') {
                newErrors[field] = `${field.replace('_', ' ')} is required.`;
                isValid = false;
            } else if (isNaN(formData[field]) || Number(formData[field]) < 0) {
                newErrors[field] = `${field.replace('_', ' ')} must be a non-negative number.`;
                isValid = false;
            } else {
                const floatValue = parseFloat(formData[field]);
                if (isNaN(floatValue)) {
                    newErrors[field] = `${field.replace('_', ' ')} must be a valid number.`;
                    isValid = false;
                }
            }
        });

        setErrors(newErrors);
        return isValid;
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevData) => {
            const updatedData = { ...prevData, [name]: value };

            // Calculate ROI, Margin if salePrice or cog or profit changes
            if (name === "sale_price" || name === "cog" || name === "profit") {
                const salePrice = parseFloat(updatedData.sale_price) || 0;
                const cog = parseFloat(updatedData.cog) || 0;
                const profit = parseFloat(updatedData.profit) || 0;

                // Calculate Margin, ROI
                const margin = salePrice > 0 ? (profit / salePrice) * 100 : 0;
                const roi = cog > 0 ? (profit / cog) * 100 : 0;

                // Update the calculated fields
                updatedData.margin = margin.toFixed(2);
                updatedData.roi = roi.toFixed(2);
            }

            return updatedData;
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setFormLoading(true);
            try {
                await axios.patch(`${serverEndpoint}/listings/update-listing/${listingId}`, {
                    instock_quantity: formData.instock_quantity,
                    sale_price: formData.sale_price,
                    cog: formData.cog,
                    margin: formData.margin,
                    roi: formData.roi,
                    profit: formData.profit
                }, { withCredentials: true });
                setFormLoading(false);
                updateListingData(formData, true);
            } catch (error) {
                setErrors({
                    message: 'Something went wrong, please try again'
                })
                setFormLoading(false);
                console.log(error);
            }
        }
    };

    const getListing = async () => {
        try {
            const response = await axios.get(`${serverEndpoint}/listings/get-listing/${listingId}`, {
                params: {
                    listingId: listingId,
                },
                withCredentials: true,
            });
            setListingData(response.data);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setErrors({ fatal: 'Something went wrong, please try again' });
            setLoading(false);
        }
    };

    useEffect(() => {
        getListing();
    }, []);

    if (loading) {
        return <Spinner />;
    }

    if (errors?.fatal) {
        return <ErrorComponent />;
    }

    return (
        <div className="container">
            <div className="row mt-4 p-3 bg-white rounded border">
                {errors.message && (
                    <div className="alert alert-danger" role="alert">
                        {errors.message}
                    </div>
                )}
                <h2 className="mb-3">{listingData.title}</h2>
                <div className="mb-3">
                    {!isEdit && (
                        <>
                            <button className="btn btn-outline-primary btn-sm me-2" onClick={handleEdit}>
                                <MdModeEdit className="mb-1" /> Edit
                            </button>
                            <button className="btn btn-outline-danger btn-sm" onClick={handleDelete}>
                                <MdDelete className="mb-1" /> Delete
                            </button>
                        </>
                    )}

                    {isEdit && (
                        <>
                            {formLoading ? (
                                <>
                                    <button className="btn btn-primary btn-sm me-2" type="button" disabled="">
                                        <span
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </button>
                                    <button className="btn btn-warning text-white btn-sm" type="button" disabled="">
                                        <span
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </button>
                                </>
                            ) : (
                                <>
                                    <button className="btn btn-primary btn-sm me-2" onClick={handleSubmit}>
                                        <MdModeEdit className="mb-1" /> Save
                                    </button>
                                    <button className="btn btn-warning text-white btn-sm" onClick={() => updateListingData({}, false)}>
                                        <MdDelete className="mb-1" /> Close
                                    </button>
                                </>
                            )}

                        </>
                    )}

                </div>
                <div className="col-12 col-lg-6 mb-4">
                    <img src={listingData.image_url ? listingData.image_url : '/images/default-image.jpg'}
                        onError={(e) => {
                            e.target.onerror = null; // Prevent infinite loop if fallback image fails
                            e.target.src = "/images/default-image.jpg";
                        }}
                        alt={listingData.title} className="rounded" style={{
                            maxHeight: '500px',
                            width: '100%',
                            objectFit: 'cover'
                        }} />
                </div>
                <div className="col-12 col-lg-6">
                    <table className="table">
                        <tbody>
                            <tr><th className="w-25">Description</th><td>{listingData.description}</td></tr>
                            <tr><th className="w-25">ASIN</th><td>{listingData.asin}</td></tr>
                            <tr><th className="w-25">SKU</th><td>{listingData.sku}</td></tr>
                            <tr>
                                <th className="w-25">Instock Qty</th>
                                <td>
                                    {isEdit ? (
                                        <div>
                                            <input id="instock_quantity" type="number" min="0" className={errors?.instock_quantity ? 'form-control is-invalid' : 'form-control'}
                                                name="instock_quantity" value={formData.instock_quantity} required autoFocus onChange={handleChange}
                                            />
                                            <div className="invalid-feedback">Please provide a valid Instock Quantity</div>
                                        </div>
                                    ) : (
                                        <>{listingData.instock_quantity}</>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th className="w-25">Sale Price</th>
                                <td>
                                    {isEdit ? (
                                        <div>
                                            <input id="sale_price" type="number" step="0.01" min="0" className={errors?.sale_price ? 'form-control is-invalid' : 'form-control'}
                                                name="sale_price" value={formData.sale_price} required onChange={handleChange}
                                            />
                                            <div className="invalid-feedback">Please provide a Sale Price</div>
                                        </div>
                                    ) : (
                                        <>{currencySymbol}{listingData.sale_price}</>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th className="w-25">COG</th>
                                <td>
                                    {isEdit ? (
                                        <div>
                                            <input id="cog" type="number" step="0.01" min="0" className={errors?.cog ? 'form-control is-invalid' : 'form-control'}
                                                name="cog" value={formData.cog} required onChange={handleChange}
                                            />
                                            <div className="invalid-feedback">Please provide a valid COG</div>
                                        </div>
                                    ) : (
                                        <>{currencySymbol}{listingData.cog}</>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th className="w-25">Margin (%)</th>
                                <td>
                                    {isEdit ? (
                                        <div>
                                            <input id="margin" type="number" step="0.01" min="0" className={errors?.margin ? 'form-control is-invalid' : 'form-control'}
                                                name="margin" value={formData.margin} onChange={handleChange} disabled
                                            />
                                            <div className="invalid-feedback">Please provide a valid Margin</div>
                                        </div>
                                    ) : (
                                        <>{listingData.cog}%</>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th className="w-25">ROI (%)</th>
                                <td>
                                    {isEdit ? (
                                        <div>
                                            <input id="roi" type="number" step="0.01" min="0" className={errors?.roi ? 'form-control is-invalid' : 'form-control'}
                                                name="roi" value={formData.roi} onChange={handleChange} disabled
                                            />
                                            <div className="invalid-feedback">Please provide a valid ROI</div>
                                        </div>
                                    ) : (
                                        <>{listingData.roi}%</>
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <th className="w-25">Profit</th>
                                <td>
                                    {isEdit ? (
                                        <div>
                                            <input id="profit" type="number" step="0.01" min="0" className={errors?.profit ? 'form-control is-invalid' : 'form-control'}
                                                name="profit" value={formData.profit} onChange={handleChange}
                                            />
                                            <div className="invalid-feedback">Please provide a valid Profit</div>
                                        </div>
                                    ) : (
                                        <>{currencySymbol}{listingData.profit}</>
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div >


            <div className="row mt-4 mb-2 p-3 bg-white rounded border">
                <h3>Associated Bundles</h3>
                <div className="col">
                    {listingData.bundles && Object.keys(listingData.bundles).length > 0 ? (
                        <Row className="mt-4">
                            {Object.entries(listingData.bundles).map(([bundleId, bundle]) => (
                                <Col xs={12} md={6} lg={3} key={bundleId} className="mb-4">
                                    <Card className="h-100">
                                        <Link to={`/bundle/${bundleId}`}>
                                            <Card.Img
                                                variant="top"
                                                src={bundle.image_url ? bundle.image_url : '/images/default-image.jpg'}
                                                alt={bundle.title}
                                                style={{ height: '200px', objectFit: 'cover' }}
                                            />
                                        </Link>
                                        <Card.Body>
                                            <Link to={`/bundle/${bundleId}`} className="text-body text-decoration-none hover:text-primary">
                                                <Card.Title>{bundle.title}</Card.Title>
                                            </Link>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    ) : (
                        <p className="text-muted">No bundles available for this listing.</p>
                    )}
                </div>
            </div>

            <ConfirmDialog show={showConfirmDeleteModal} handleClose={handleConfirmDeleteModalClose}
                title="Delete Listing Confirmation"
                message="Are you sure you want to delete the listing?"
            />
        </div >

    );
}

export default ViewListing;