import React, { useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FaCaretDown } from "react-icons/fa";
import {
    CContainer, CHeader, CHeaderNav, CHeaderToggler, CNavLink, CNavItem
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import {
    CDropdown, CDropdownDivider, CDropdownHeader, CDropdownMenu, CDropdownToggle
} from '@coreui/react'
import {
    cilCreditCard,
    cilLockLocked,
    cibAuth0,
    cilMenu,
    cibAmazon,
    cilMoney
} from '@coreui/icons'

function AppHeader({ userDetails }) {
    const headerRef = useRef()

    const dispatch = useDispatch()
    const sidebarShow = useSelector((state) => state.sidebarShow)

    return (
        <CHeader position="sticky" className="mb-4 p-0" ref={headerRef}>
            <CContainer className="border-bottom px-4" fluid>
                <CHeaderToggler
                    onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
                    style={{ marginInlineStart: '-14px' }}
                >
                    <CIcon icon={cilMenu} size="lg" />
                </CHeaderToggler>
                <CHeaderNav className="d-none d-md-flex">
                    <CNavItem>
                        <CNavLink to="/dashboard" as={NavLink}>
                            Dashboard
                        </CNavLink>
                    </CNavItem>
                </CHeaderNav>
                <CHeaderNav className='ms-auto'>
                    <CDropdown variant="nav-item">
                        <CDropdownToggle placement="bottom-end" className="py-0 pe-0" caret={false}>
                            {userDetails.displayEmail} <FaCaretDown size={15} className="mb-1" />
                        </CDropdownToggle>
                        <CDropdownMenu className="pt-0" placement="bottom-end">
                            <CDropdownHeader className="bg-body-secondary fw-semibold">Settings</CDropdownHeader>

                            <Link to="/mfa" className="dropdown-item">
                                <CIcon icon={cibAuth0} className="me-2" />
                                Two-Factor Auth
                            </Link>
                            <Link to="/seller-account" className="dropdown-item">
                                <CIcon icon={cibAmazon} className="me-2" />
                                Seller Account
                            </Link>
                            <CDropdownHeader className="bg-body-secondary fw-semibold">Payments</CDropdownHeader>
                            <Link to="/manage-subscription" className="dropdown-item">
                                <CIcon icon={cilCreditCard} className="me-2" />
                                Subscription
                            </Link>
                            <Link to="/manage-referral" className="dropdown-item">
                                <CIcon icon={cilMoney} className="me-2" />
                                Referral
                            </Link>
                            <CDropdownDivider />

                            <Link to="/logout" className="dropdown-item">
                                <CIcon icon={cilLockLocked} className="me-2" />
                                Logout
                            </Link>

                        </CDropdownMenu>
                    </CDropdown>
                </CHeaderNav>
            </CContainer>
        </CHeader>
    )
}

export default AppHeader
