import { serverEndpoint } from "./config";
import axios from 'axios';

const triggerVerifyEmail = async (email) => {
    try {
        await axios.post(`${serverEndpoint}/auth/trigger-email-verification`, { email: email }, { withCredentials: true });
    } catch (err) {
        // Ignore this error, we'll offer resend email code link
        console.log(err);
    }
};

export default triggerVerifyEmail;