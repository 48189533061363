import axios from 'axios';
import { serverEndpoint } from '../../util/config';
import { useNavigate } from 'react-router-dom';

function Logout({ updateUserDetails }) {
    const navigate = useNavigate();

    axios.post(`${serverEndpoint}/auth/logout`, null, { withCredentials: true })
        .then(response => {
            document.cookie = `accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
            updateUserDetails(null);
        })
        .catch(error => {
            console.log(error);
            navigate('/dashboard'); // Update it to server-error page
        });
}

export default Logout;