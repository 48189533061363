import { legacy_createStore as createStore } from 'redux';

const initialState = {
    sidebarShow: true,
    theme: 'dark',
    triggerFetch: false,  // Add triggerFetch state
    userDetails: null,      // Add userDetails state to store fetched data
}

const changeState = (state = initialState, { type, ...rest }) => {
    switch (type) {
        case 'set':
            return { ...state, ...rest }
        case 'toggleFetchTrigger':  // New action to toggle triggerFetch
            return { ...state, triggerFetch: !state.triggerFetch }
        case 'setUserDetails':      // New action to set user details
            return { ...state, userDetails: rest.userDetails }
        default:
            return state
    }
}

const store = createStore(changeState)
export default store;
