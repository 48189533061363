import Box from '@mui/material/Box';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from "axios";
import { useEffect, useState } from "react";
import { CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react';
import Spinner from '../../../components/Spinner';
import { serverEndpoint } from "../../../util/config";
import { MdEdit } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { IoMdEye } from "react-icons/io";
import { Modal } from "react-bootstrap";
import { MdEmail } from "react-icons/md";
import ConfirmDialog from '../../../components/ConfirmDialog';
import UpdateUser from './UpdateUser';
import { Tooltip } from '@mui/material';

function ViewUsers({ userDetails }) {
    const [isUpdateMode, setIsUpdateMode] = useState(false);
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState(null);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState(null);
    const theme = createTheme({
        components: {
            MuiDataGrid: {
                styleOverrides: {
                    root: {
                        padding: '10px',
                        border: 'none',
                    }
                },
            },
        },
    });

    const [permissions, setPermissions] = useState({});
    const [showPermissionModal, setShowPermissionModal] = useState(false);
    const handlePermissionModalClose = () => setShowPermissionModal(false);
    const handlePermissionModalShow = (permissions) => {
        setPermissions(permissions);
        setShowPermissionModal(true);
    };

    const [user, setUser] = useState({});
    const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
    const handleDeleteUserModalClose = (confirmed) => {
        setShowDeleteUserModal(false);
        if (confirmed) {
            setLoading(true);
            axios.post(`${serverEndpoint}/user/delete-user`, {
                username: user.email
            }, { withCredentials: true })
                .then(response => {
                    const updatedUsers = users.filter(u => u.email !== user.email);
                    setUsers(updatedUsers);
                    setLoading(false);
                }).catch(error => {
                    console.log(error);
                    setErrors({ message: "Unable to delete user, try again!" });
                    setLoading(false);
                });
        }
    };
    const handleDeleteUserModalShow = (user) => {
        setUser(user);
        setShowDeleteUserModal(true);
    };

    const handleUpdateUserAction = (user) => {
        setUser(user);
        setIsUpdateMode(true);
    };

    const handleUserUpdate = (newUserData) => {
        setUsers(prevUsers => {
            const updatedUsers = prevUsers.map(u =>
                u.email === newUserData.email ? { ...u, ...newUserData } : u
            );
            return updatedUsers;
        });
        setIsUpdateMode(false);
    };

    const handleResendTemporaryPassword = (email) => {
        setMessage('Re-sending temporary password...');
        axios.post(`${serverEndpoint}/user/resend-password`, {
            username: email
        }, { withCredentials: true })
            .then(response => {
                setMessage(`Temporary password sent to ${email}`);
            }).catch(error => {
                console.log(error);
                setMessage(null);
                setErrors({ message: "Unable to resend temporary password" });
            });
    };

    const fetchUsers = async () => {
        axios.post(`${serverEndpoint}/user/get-users`, {
            master_account_id: userDetails.masterAccountId
        }, { withCredentials: true })
            .then(response => {
                setUsers(response.data.users);
                setLoading(false);
            }).catch(error => {
                console.log(error);
                if (error.response?.data?.error === 'User already exists') {
                    setErrors({ message: "An account already exists with the provided email." });
                } else {
                    setErrors({ message: "Something went wrong, please try again!" });
                }
                setLoading(false);
            });
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    if (loading) {
        return <Spinner />
    }

    const permissionsTemplate = {
        "User Management": ["Add", "Edit", "Delete", "View"],
        "Inbound": ["Add", "Edit", "Delete", "View"],
        "In-stock": ["Add", "Edit", "Delete", "View"],
        "Component Library": ["Add", "Edit", "Delete", "View"],
        "Amazon Listing": ["Add", "Edit", "Delete", "View"],
        "Task Management": ["Add", "Edit", "Delete", "View"],
        "Reporting": ["Add", "Edit", "Delete", "View"]
    };

    const columns = [
        { field: 'id', headerName: 'User ID', width: 300 },
        {
            field: 'email',
            headerName: 'Email',
            width: 250,
            editable: false
        },
        {
            field: 'phone',
            headerName: 'Phone',
            width: 150,
            editable: false,
        },
        {
            field: 'role',
            headerName: 'Role',
            width: 150,
            editable: false,
            renderCell: (params) => (
                <>
                    {params.row.role.charAt(0).toUpperCase() + params.row.role.slice(1)}
                </>
            )
        },
        {
            field: 'permissions',
            headerName: 'Permissions',
            width: 100,
            editable: false,
            renderCell: (params) => (
                <>
                    <IoMdEye size={24} className="mx-1" onClick={() => handlePermissionModalShow(params.row.permissions)} />
                </>
            )
        },
        {
            field: 'action',
            headerName: 'Actions',
            width: 110,
            editable: false,
            renderCell: (params) => (
                <div>
                    <Tooltip title="Edit" placement="top-start">
                        <MdEdit size={24} className="mx-1" onClick={() => handleUpdateUserAction(params.row)} />
                    </Tooltip>
                    <Tooltip title="Delete" arrow>
                        <MdDelete size={24} className="mx-1" onClick={() => handleDeleteUserModalShow(params.row)} />
                    </Tooltip>
                    <Tooltip title="Resend Temporary Password" arrow>
                        <MdEmail size={24} className="mx-1" onClick={() => handleResendTemporaryPassword(params.row.email)} />
                    </Tooltip>
                </div>
            )
        },
    ];

    return (
        <CRow>
            <CCol xs={12}>
                <CCard className="mb-4">
                    {!isUpdateMode && (
                        <>
                            <CCardHeader>
                                <strong>Users in your Business Account</strong>
                            </CCardHeader>
                            <CCardBody>
                                {message && (
                                    <div className="alert alert-success" role="alert">
                                        {message}
                                    </div>
                                )}

                                {errors.message && (
                                    <div className="alert alert-danger" role="alert">
                                        {errors.message}
                                    </div>
                                )}

                                {users && (
                                    <ThemeProvider theme={theme}>
                                        <Box>
                                            <DataGrid
                                                initialState={{
                                                    columns: {
                                                        columnVisibilityModel: {
                                                            // Hide columns status and traderName, the other columns will remain visible
                                                            id: false
                                                        },
                                                    },
                                                }}
                                                rows={users}
                                                columns={columns}
                                                disableRowSelectionOnClick
                                                slots={{
                                                    toolbar: GridToolbarQuickFilter,
                                                }}
                                                slotProps={{
                                                    toolbar: {
                                                        showQuickFilter: true,
                                                    },
                                                }}
                                            />
                                        </Box>
                                    </ThemeProvider>
                                )}

                            </CCardBody>
                        </>
                    )}

                    {isUpdateMode && (
                        <>
                            <CCardHeader>
                                <strong>Update User</strong>
                            </CCardHeader>
                            <CCardBody>
                                {errors.message && (
                                    <div className="alert alert-danger" role="alert">
                                        {errors.message}
                                    </div>
                                )}

                                {users && (
                                    <UpdateUser userDetails={user} updateUserDetails={handleUserUpdate} />
                                )}

                            </CCardBody>
                        </>
                    )}

                </CCard>
            </CCol>

            <Modal
                show={showPermissionModal} onHide={handlePermissionModalClose} backdrop="static"
                keyboard={true} size="lg" aria-labelledby="contained-modal-title-vcenter" centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Permissions
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {Object.keys(permissionsTemplate).map((section) => (
                        <div key={section} className="border p-2 m-1 rounded-2">
                            <h6>{section}</h6>
                            <div className="d-flex flex-wrap">
                                {permissionsTemplate[section].map((action) => (
                                    <div className="form-check form-check-inline" key={action}>
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id={`${section}-${action}`}
                                            checked={permissions[section]?.includes(action)}
                                            disabled
                                        />
                                        <label className="form-check-label" htmlFor={`${section}-${action}`}>
                                            {action}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </Modal.Body>
            </Modal>

            {/* Delete user Modal */}
            <ConfirmDialog show={showDeleteUserModal} handleClose={handleDeleteUserModalClose}
                title="Delete User Confirmation"
                message="Are you sure you want to delete this user?"
            />
        </CRow>
    );
}

export default ViewUsers;