import axios from "axios";
import { useEffect, useState } from "react";
import Spinner from "../../components/Spinner";
import { serverEndpoint } from "../../util/config";
import { Card, Col, Row, Modal, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BsTelephoneInbound } from "react-icons/bs";
import ErrorComponent from "../../components/Error";
import { useSelector } from 'react-redux';
import { hasPermission } from "../../util/permissionsEvaluator";
import { DEFAULT_PAGE_SIZE } from "../../util/constants";

function ViewComponents() {
    const userDetails = useSelector((state) => state.userDetails);
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});
    const [componentsData, setComponentsData] = useState([]);
    const [displayData, setDisplayData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [paginationKey, setPaginationKey] = useState(null);
    const [itemsLoading, setItemsLoading] = useState(false);
    const [localDataAvailable, setLocalDataAvailable] = useState(false);

    const getComponents = async () => {
        if (itemsLoading || localDataAvailable) return;

        try {
            setItemsLoading(true);
            const params = {
                limit: DEFAULT_PAGE_SIZE
            };
            if (paginationKey) {
                params.lastEvaluatedKey = paginationKey;
            }

            const response = await axios.get(`${serverEndpoint}/components/get-components`, {
                params: params,
                withCredentials: true,
            });
            setComponentsData((prev) => [...prev, ...response.data.items]);
            setDisplayData((prev) => [...prev, ...response.data.items]);
            setPaginationKey(response.data.lastEvaluatedKey);
            if (!response.data.lastEvaluatedKey) setLocalDataAvailable(true);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setErrors({ fatal: 'Something went wrong, please try again!' })
            setLoading(false);
        } finally {
            setItemsLoading(false);
        }
    };

    useEffect(() => {
        getComponents();
    }, []);

    const getSearchResults = async () => {
        try {
            setItemsLoading(true);
            const params = {
                limit: DEFAULT_PAGE_SIZE
            };
            if (paginationKey) {
                params.lastEvaluatedKey = paginationKey;
            }

            const response = await axios.get(`${serverEndpoint}/components/get-components/${searchQuery}`, { withCredentials: true, });
            setDisplayData(response.data.items);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setErrors({ fatal: 'Something went wrong, please try again!' });
            setLoading(false);
        } finally {
            setItemsLoading(false);
        }
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchQuery.trim() === "") {
                setDisplayData([...componentsData]); // Reset to full data if search term is empty
            } else {
                const searchResults = paginationKey === null
                    ? componentsData.filter((item) =>
                        item.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        item.description?.toLowerCase().includes(searchQuery.toLowerCase())
                    ) // Local search
                    : null;

                if (searchResults !== null) {
                    setDisplayData(searchResults);
                } else {
                    getSearchResults();
                }
            }
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [searchQuery, componentsData]);

    const [markInboundLoading, setMarkInboundLoading] = useState(false);
    const [showMarkInboundModal, setShowMarkInboundModal] = useState(false);
    const [markInboundFormData, setMarkInboundFormData] = useState({
        qtyInbound: 0
    });

    const handleClose = () => setShowMarkInboundModal(false);
    const handleShow = (inboundItem) => {
        if (!hasPermission(userDetails, "Inbound", "Add")) {
            setErrors({ message: 'You do not have permission to perform this action.' });
            return;
        }

        setErrors({});
        setMarkInboundFormData({
            inboundQty: 0,
            itemId: inboundItem.sk.split('C#')[1],
            title: inboundItem.title,
            imageKey: inboundItem.image_key,
            imageUrl: inboundItem.image_url
        });
        setShowMarkInboundModal(true);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setMarkInboundFormData({
            ...markInboundFormData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (markInboundFormData.inboundQty <= 0) {
            setErrors({
                inboundQty: 'Please enter a valid Quantity'
            });
            return;
        }

        setMarkInboundLoading(true);
        const components = {};
        components[markInboundFormData.itemId] = {
            title: markInboundFormData.title,
            image_key: markInboundFormData.imageKey,
            image_url: markInboundFormData.imageUrl,
            inboundQty: markInboundFormData.inboundQty,
        };

        axios.post(`${serverEndpoint}/inbound/create`, {
            listings: {},
            components: components
        }, { withCredentials: true })
            .then(response => {
                setMarkInboundLoading(false);
                handleClose();
            }).catch(error => {
                console.log(error);
                setErrors({ message: "Unable to mark listing inbound!" });
            });
    };

    if (loading) {
        return <Spinner />;
    }

    if (errors?.fatal) {
        return <ErrorComponent />;
    }

    return (
        <>
            <section>
                <div className="container bg-white p-4 rounded border">
                    <div className="">
                        {errors.message && (
                            <div className="alert alert-danger" role="alert">
                                {errors.message}
                            </div>
                        )}
                        <h2>Components</h2>
                        <p className="text-secondary mb-2">View & Manage all your Components</p>
                    </div>
                    <hr className="text-muted text-center" />
                    <div className="my-3">
                        <Form.Control
                            type="text"
                            placeholder="Search by title or Description"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        {itemsLoading && (
                            <div className="d-flex justify-content-center mt-4">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Item display */}
                    <Row className="mt-2 text-center">
                        {displayData.length === 0 && !itemsLoading && (
                            <div className="d-flex justify-content-center mt-4">
                                <p>No results found</p>
                            </div>
                        )}

                        {displayData.map((component) => (
                            <Col xs={12} md={6} lg={3} className="d-flex px-2 my-2" key={component.sk}>
                                <Card className="position-relative h-100 d-flex flex-column">
                                    <Card.Body className="d-flex justify-content-center align-items-center px-4">
                                        <Link to={`/component/${component.sk.split('C#')[1]}`}>
                                            <img src={component.image_url ? component.image_url : '/images/default-image.jpg'}
                                                onError={(e) => {
                                                    e.target.onerror = null; // Prevent infinite loop if fallback image fails
                                                    e.target.src = "/images/default-image.jpg";
                                                }}
                                                alt={component.title} className="rounded" style={{
                                                    maxHeight: '210px',
                                                    width: '100%',
                                                    objectFit: 'cover'
                                                }} />
                                        </Link>
                                    </Card.Body>
                                    <Card.Body className="flex-grow-1 pt-0">
                                        <Link to={`/component/${component.sk.split('C#')[1]}`} className="text-body text-decoration-none hover:text-primary">
                                            <h5 className="w-100" title={component.title}>
                                                {component.title.length > 20 ? `${component.title.substring(0, 20)}...` : component.title}
                                            </h5>
                                        </Link>
                                    </Card.Body>
                                    <div className="d-flex justify-content-center mb-4">
                                        <div className="mx-2">
                                            <button className="btn btn-sm btn-outline-success" onClick={() => handleShow(component)}>
                                                <BsTelephoneInbound size={15} /> Mark Inbound
                                            </button>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        ))}
                    </Row>

                    {paginationKey && searchQuery.length === 0 && (
                        <div className="row justify-content-center mt-4">
                            <div className="col-auto">

                                {itemsLoading ? (
                                    <button className="btn btn-primary" type="button" disabled="">
                                        <span
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </button>
                                ) : (
                                    <button onClick={() => getComponents()} className="btn btn-primary">
                                        Load More
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </section>

            <Modal show={showMarkInboundModal} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Enter Inbound Quantity</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label className="mb-2 text-muted" htmlFor="inboundQty">
                                {markInboundFormData.title}
                            </label>
                            <input id="inboundQty" type="number" className={errors?.inboundQty ? 'form-control is-invalid' : 'form-control'}
                                name="inboundQty" value={markInboundFormData.inboundQty} autoFocus onChange={handleChange}
                            />
                            <div className="invalid-feedback">Please provide a valid Quantity</div>
                        </div>

                        <div className="align-items-center d-flex">
                            {markInboundLoading && (
                                <button className="btn btn-primary ms-auto" type="button" disabled>
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </button>
                            )}

                            {!markInboundLoading && (
                                <button className="btn btn-primary ms-auto" type="submit">
                                    Submit
                                </button>
                            )}
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ViewComponents;
