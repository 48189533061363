import { useState, useEffect } from "react";
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";

function AuthorizationResult() {
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        const queryString = window.location.search;
        const queryParams = new URLSearchParams(queryString);
        const oauthResult = queryParams.get('oauth');
        
        if (oauthResult) {
            if (oauthResult === 'success') {
                setSuccess(true);
            } else if (oauthResult === 'failure') {
                setError(true);
            }
        }
    }, []); // Empty dependency array to run only once on mount

    return (
        <Container className="my-5 bg-white border rounded p-3">
            <Row className="justify-content-center">
                <Col md={12}>
                    {error && (
                        <>
                            <h2 className="text-danger">Authorization Failed</h2>
                            <hr/>
                            <p className="text-muted">
                                Please try connecting your Amazon <Link to="/seller-account">Seller Account</Link> again. Ensure that you do not refresh the page or close the window while the authorization is in progress.
                            </p>
                        </>
                    )}

                    {success && (
                        <>
                            <h2 className="text-success">Authorization Successful</h2>
                            <hr/>
                            <p className="text-muted">
                                Your Amazon Seller account is now connected to JBL Prep Master. You can start importing your Amazon listings from the <Link to="/seller-account">Seller Account</Link> page.
                            </p>
                        </>
                    )}
                </Col>
            </Row>
        </Container>
    );
}

export default AuthorizationResult;
