import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import Signin from "./pages/auth/Signin";
import Signup from "./pages/auth/Signup";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import Dashboard from "./pages/dashboard/Dashboard";
import Logout from "./pages/auth/Logout";
import Spinner from "./components/Spinner";
import ForgotPassword from "./pages/auth/ForgotPassword";
import MultiFactorAuthenticationSetting from "./pages/dashboard/settings/MultiFactorAuthenticationSetting";
import { serverEndpoint } from "./util/config";
import AppLayout from "./layout/AppLayout";
import './scss/style.scss'
import AddUser from "./pages/dashboard/user/AddUser";
import ViewUsers from "./pages/dashboard/user/ViewUsers";
import UnauthorizedAccess from "./pages/UnauthorizedAccess";
import ProtectedRoute from "./components/ProtectedRoute";
import PaymentSuccess from "./pages/payments/PaymentSuccess";
import ManageSubscriptions from "./pages/dashboard/payment/ManageSubscription";
import ChangeSubscription from "./pages/dashboard/payment/ChangeSubscription";
import PaymentPlans from "./pages/payments/PaymentPlans";
import { useDispatch, useSelector } from "react-redux";
import ViewInboundItems from "./pages/inbound/ViewInboundItems";
import ViewListings from "./pages/listings/ViewListings";
import ViewListing from "./pages/listings/ViewListing";
import AddComponent from "./pages/productComponents/AddComponent";
import ViewComponents from "./pages/productComponents/ViewComponents";
import ViewComponent from "./pages/productComponents/ViewComponent";
import AddBundle from "./pages/bundles/AddBundle";
import ViewBundles from "./pages/bundles/ViewBundles";
import ViewBundle from "./pages/bundles/ViewBundle";
import ViewInstockItems from "./pages/instock/ViewInstockItems";
import AddTask from "./pages/tasks/AddTask";
import ViewTasks from "./pages/tasks/ViewTasks";
import AmazonSellerAccount from "./pages/amazonSeller/AmazonSellerAccount";
import AuthorizationResult from "./pages/amazonSeller/AuthorizationResult";
import { USER_ROLES } from "./util/constants";
import ManageReferral from "./pages/referral/ManageReferral";
import ViewCompletedTasksV2 from "./pages/tasks/ViewCompletedTasksV2";
import Reports from "./pages/reports/Reports";

function App() {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const triggerFetch = useSelector((state) => state.triggerFetch);
    const userDetails = useSelector((state) => state.userDetails);

    const updateUserDetails = (newUserDetails) => {
        dispatch({ type: 'setUserDetails', userDetails: newUserDetails });
    };

    // Using useEffect to navigate after userDetails are updated
    useEffect(() => {
        if (userDetails?.hasTrialEnded) {
            if (userDetails?.stripeObject?.payment_required) {
                navigate('/subscribe');
            }
        }
    }, [userDetails, navigate]); // Runs every time userDetails or navigate changes

    const isUserLoggedIn = useCallback(() => {
        setLoading(true);
        axios.get(`${serverEndpoint}/authorize/is-authorized-user`, { withCredentials: true })
            .then(response => {
                const user = response.data.user;
                dispatch({ type: 'setUserDetails', userDetails: user });
                setLoading(false);
            })
            .catch(error => {
                // Assuming the user is not logged in!
                setLoading(false);
            });
    }, [dispatch]);

    useEffect(() => {
        isUserLoggedIn();
    }, [triggerFetch, isUserLoggedIn]);

    if (loading) {
        return <Spinner />;
    }

    return (
        <>
            <Routes>
                <Route path="/" element={userDetails ? <Navigate to="/dashboard" /> : <Signin updateUserDetails={updateUserDetails} />} />
                <Route path="/signin" element={userDetails ? <Navigate to="/dashboard" /> : <Signin updateUserDetails={updateUserDetails} />} />
                <Route path="/forgot-password" element={userDetails ? <Navigate to="/dashboard" /> : <ForgotPassword />} />
                <Route path="/signup" element={userDetails ? <Navigate to="/dashboard" /> : <Signup />} />
                <Route path="/logout" element={userDetails ? <Logout updateUserDetails={updateUserDetails} /> : <Navigate to="/signin" />} />
                <Route path="/manage-subscription" element={
                    userDetails ? (
                        <ProtectedRoute
                            userDetails={userDetails}
                            userPermissions={userDetails?.permissions}
                            isProtected={true}
                            role={USER_ROLES.ADMIN}
                            element={
                                <AppLayout userDetails={userDetails}>
                                    <ManageSubscriptions userDetails={userDetails} />
                                </AppLayout>
                            }
                        />
                    ) : (
                        <Navigate to="/signin" />
                    )
                }
                />
                <Route path="/change-subscription" element={
                    userDetails ? (
                        <ProtectedRoute
                            userDetails={userDetails}
                            userPermissions={userDetails?.permissions}
                            isProtected={true}
                            role={USER_ROLES.ADMIN}
                            element={
                                <AppLayout userDetails={userDetails}>
                                    <ChangeSubscription userDetails={userDetails} />
                                </AppLayout>
                            }
                        />
                    ) : (
                        <Navigate to="/signin" />
                    )
                }
                />
                <Route path="/dashboard" element={
                    userDetails ? (
                        <AppLayout userDetails={userDetails}>
                            <Dashboard />
                        </AppLayout>
                    ) : (
                        <Navigate to="/signin" />
                    )
                }
                />
                <Route path="/mfa" element={
                    userDetails ? (
                        <AppLayout userDetails={userDetails}>
                            <MultiFactorAuthenticationSetting userDetails={userDetails} />
                        </AppLayout>
                    ) : (
                        <Navigate to="/signin" />
                    )
                }
                />
                <Route path="/seller-account" element={
                    userDetails ? (
                        <ProtectedRoute
                            userDetails={userDetails}
                            section="Amazon Listing"
                            action="Add"
                            userPermissions={userDetails?.permissions}
                            isProtected={true}
                            element={
                                <AppLayout userDetails={userDetails}>
                                    <AmazonSellerAccount />
                                </AppLayout>
                            }
                        />
                    ) : (
                        <Navigate to="/signin" />
                    )
                }
                />
                <Route path="/amazon/oauth" element={
                    userDetails ? (
                        <AppLayout userDetails={userDetails}>
                            <AuthorizationResult />
                        </AppLayout>
                    ) : (
                        <Navigate to="/signin" />
                    )
                }
                />
                <Route
                    path="/users/add-user"
                    element={
                        <ProtectedRoute
                            userDetails={userDetails}
                            section="User Management"
                            action="Add"
                            userPermissions={userDetails?.permissions}
                            isProtected={true}
                            element={
                                <AppLayout userDetails={userDetails}>
                                    <AddUser userDetails={userDetails} />
                                </AppLayout>
                            }
                        />
                    }
                />
                <Route
                    path="/users/view-all"
                    element={
                        <ProtectedRoute
                            userDetails={userDetails}
                            section="User Management"
                            action="View"
                            userPermissions={userDetails?.permissions}
                            isProtected={true}
                            element={
                                <AppLayout userDetails={userDetails}>
                                    <ViewUsers userDetails={userDetails} />
                                </AppLayout>
                            }
                        />
                    }
                />
                <Route path="/unauthorized" element={userDetails ? (
                    <AppLayout userDetails={userDetails}>
                        <UnauthorizedAccess />
                    </AppLayout>
                ) : (
                    <UnauthorizedAccess />
                )} />

                <Route path="/payment-success" element={userDetails ? (
                    <AppLayout userDetails={userDetails}>
                        <PaymentSuccess userDetails={userDetails} />
                    </AppLayout>
                ) : (
                    <PaymentSuccess />
                )} />

                <Route path="/subscribe" element={userDetails && userDetails.stripeObject?.payment_required ? (
                    <PaymentPlans userDetails={userDetails} isLoggedIn={true} />
                ) : (
                    <Navigate to="/signin" />
                )} />

                <Route path="/inbound" element={userDetails ? (
                    <ProtectedRoute
                        userDetails={userDetails}
                        section="Inbound"
                        action="View"
                        userPermissions={userDetails?.permissions}
                        isProtected={true}
                        element={
                            <AppLayout userDetails={userDetails}>
                                <ViewInboundItems userDetails={userDetails} />
                            </AppLayout>
                        }
                    />
                ) : (
                    <Navigate to="/signin" />
                )} />

                <Route path="/listings" element={userDetails ? (
                    <ProtectedRoute
                        userDetails={userDetails}
                        section="Amazon Listing"
                        action="View"
                        userPermissions={userDetails?.permissions}
                        isProtected={true}
                        element={
                            <AppLayout userDetails={userDetails}>
                                <ViewListings userDetails={userDetails} />
                            </AppLayout>
                        }
                    />
                ) : (
                    <Navigate to="/signin" />
                )} />

                <Route path="/listing/:listingId" element={userDetails ? (
                    <ProtectedRoute
                        userDetails={userDetails}
                        section="Amazon Listing"
                        action="View"
                        userPermissions={userDetails?.permissions}
                        isProtected={true}
                        element={
                            <AppLayout userDetails={userDetails}>
                                <ViewListing />
                            </AppLayout>
                        }
                    />
                ) : (
                    <Navigate to="/signin" />
                )} />

                <Route path="/add-component" element={userDetails ? (
                    <ProtectedRoute
                        userDetails={userDetails}
                        section="Component Library"
                        action="Add"
                        userPermissions={userDetails?.permissions}
                        isProtected={true}
                        element={
                            <AppLayout userDetails={userDetails}>
                                <AddComponent />
                            </AppLayout>
                        }
                    />
                ) : (
                    <Navigate to="/signin" />
                )} />

                <Route path="/components" element={userDetails ? (
                    <ProtectedRoute
                        userDetails={userDetails}
                        section="Component Library"
                        action="View"
                        userPermissions={userDetails?.permissions}
                        isProtected={true}
                        element={
                            <AppLayout userDetails={userDetails}>
                                <ViewComponents />
                            </AppLayout>
                        }
                    />
                ) : (
                    <Navigate to="/signin" />
                )} />

                <Route path="/component/:componentId" element={userDetails ? (
                    <ProtectedRoute
                        userDetails={userDetails}
                        section="Component Library"
                        action="View"
                        userPermissions={userDetails?.permissions}
                        isProtected={true}
                        element={
                            <AppLayout userDetails={userDetails}>
                                <ViewComponent />
                            </AppLayout>
                        }
                    />
                ) : (
                    <Navigate to="/signin" />
                )} />

                <Route path="/bundle/add" element={userDetails ? (
                    <ProtectedRoute
                        userDetails={userDetails}
                        section="Bundles"
                        action="Add"
                        userPermissions={userDetails?.permissions}
                        isProtected={true}
                        element={
                            <AppLayout userDetails={userDetails}>
                                <AddBundle />
                            </AppLayout>
                        }
                    />
                ) : (
                    <Navigate to="/signin" />
                )} />

                <Route path="/bundles" element={userDetails ? (
                    <ProtectedRoute
                        userDetails={userDetails}
                        section="Bundles"
                        action="View"
                        userPermissions={userDetails?.permissions}
                        isProtected={true}
                        element={
                            <AppLayout userDetails={userDetails}>
                                <ViewBundles />
                            </AppLayout>
                        }
                    />
                ) : (
                    <Navigate to="/signin" />
                )} />

                <Route path="/bundle/:bundleId" element={userDetails ? (
                    <ProtectedRoute
                        userDetails={userDetails}
                        section="Bundles"
                        action="View"
                        userPermissions={userDetails?.permissions}
                        isProtected={true}
                        element={
                            <AppLayout userDetails={userDetails}>
                                <ViewBundle />
                            </AppLayout>
                        }
                    />
                ) : (
                    <Navigate to="/signin" />
                )} />

                <Route path="/instock/" element={userDetails ? (
                    <ProtectedRoute
                        userDetails={userDetails}
                        section="In-stock"
                        action="View"
                        userPermissions={userDetails?.permissions}
                        isProtected={true}
                        element={
                            <AppLayout userDetails={userDetails}>
                                <ViewInstockItems />
                            </AppLayout>
                        }
                    />
                ) : (
                    <Navigate to="/signin" />
                )} />

                <Route path="/task/add" element={userDetails ? (
                    <ProtectedRoute
                        userDetails={userDetails}
                        section="Task Management"
                        action="Add"
                        userPermissions={userDetails?.permissions}
                        isProtected={true}
                        element={
                            <AppLayout userDetails={userDetails}>
                                <AddTask />
                            </AppLayout>
                        }
                    />
                ) : (
                    <Navigate to="/signin" />
                )} />

                <Route path="/tasks" element={userDetails ? (
                    <ProtectedRoute
                        userDetails={userDetails}
                        section="Task Management"
                        action="View"
                        userPermissions={userDetails?.permissions}
                        isProtected={true}
                        element={
                            <AppLayout userDetails={userDetails}>
                                <ViewTasks />
                            </AppLayout>
                        }
                    />
                ) : (
                    <Navigate to="/signin" />
                )} />

                <Route path="/task-history" element={userDetails ? (
                    <ProtectedRoute
                        userDetails={userDetails}
                        section="Task Management"
                        action="View"
                        userPermissions={userDetails?.permissions}
                        isProtected={true}
                        element={
                            <AppLayout userDetails={userDetails}>
                                <ViewCompletedTasksV2 />
                            </AppLayout>
                        }
                    />
                ) : (
                    <Navigate to="/signin" />
                )} />
                
                <Route path="/reports" element={userDetails ? (
                    <ProtectedRoute
                        userDetails={userDetails}
                        section="Task Management"
                        action="View"
                        userPermissions={userDetails?.permissions} // I have to ask for the permission, which permissions to give   
                        isProtected={false}
                        element={
                            <AppLayout userDetails={userDetails}>
                                <Reports />
                            </AppLayout>
                        }
                    />
                ) : (
                    <Navigate to="/signin" />
                )} />

                <Route path="/manage-referral" element={
                    userDetails ? (
                        <ProtectedRoute
                            userDetails={userDetails}
                            userPermissions={userDetails?.permissions}
                            isProtected={true}
                            role={USER_ROLES.ADMIN}
                            element={
                                <AppLayout userDetails={userDetails}>
                                    <ManageReferral />
                                </AppLayout>
                            }
                        />
                    ) : (
                        <Navigate to="/signin" />
                    )
                }
                />
            </Routes>
        </>
    );
}

export default App;
