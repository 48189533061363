import axios from "axios";
import { useEffect, useState } from "react";
import Spinner from "../../components/Spinner";
import { serverEndpoint } from "../../util/config";
import { Card, Col, Row, Modal, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import ErrorComponent from "../../components/Error";
import { hasPermission } from "../../util/permissionsEvaluator";
import { DEFAULT_PAGE_SIZE } from "../../util/constants";

function ViewInboundItems({ userDetails }) {
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});
    const [inboundItemsData, setInboundItemsData] = useState([]);
    const [displayData, setDisplayData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [paginationKey, setPaginationKey] = useState(null);
    const [itemsLoading, setItemsLoading] = useState(false);
    const [localDataAvailable, setLocalDataAvailable] = useState(false);

    // Fetch inbound items
    const getInboundItems = async () => {
        if (itemsLoading || localDataAvailable) return;

        try {
            setItemsLoading(true);
            const params = {
                limit: DEFAULT_PAGE_SIZE,
                email: userDetails.username,
            };
            if (paginationKey) {
                params.lastEvaluatedKey = paginationKey;
            }

            const response = await axios.get(`${serverEndpoint}/inbound/items`, {
                params: params,
                withCredentials: true
            });
            const updatedItems = response.data.items.map(item => ({
                ...item,
                url: item.type === "Listing" ? `/listing/${item.itemId}` : `/component/${item.itemId}`
            }));
            setInboundItemsData((prev) => [...prev, ...updatedItems]);
            setDisplayData((prev) => [...prev, ...updatedItems]);
            setPaginationKey(response.data.lastEvaluatedKey);
            if (!response.data.lastEvaluatedKey) setLocalDataAvailable(true);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setErrors({ fatal: 'Something went wrong, please try again!' });
            setLoading(false);
        } finally {
            setItemsLoading(false);
        }
    };

    useEffect(() => {
        getInboundItems();
    }, []);

    const getSearchResults = async () => {
        try {
            setItemsLoading(true);
            const response = await axios.get(`${serverEndpoint}/inbound/items/${searchQuery}`, {
                params: {
                    email: userDetails.username,
                },
                withCredentials: true,
            });
            setDisplayData(response.data.items);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setErrors({ fatal: 'Something went wrong, please try again!' });
            setLoading(false);
        } finally {
            setItemsLoading(false);
        }
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchQuery.trim() === "") {
                setDisplayData([...inboundItemsData]); // Reset to full data if search term is empty
            } else {
                const searchResults = paginationKey === null
                    ? inboundItemsData.filter((item) =>
                        item.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        item.description?.toLowerCase().includes(searchQuery.toLowerCase())
                    ) // Local search
                    : null;

                if (searchResults !== null) {
                    setDisplayData(searchResults);
                } else {
                    getSearchResults();
                }
            }
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [searchQuery, inboundItemsData]);

    const [markArrivedLoading, setMarkArrivedLoading] = useState(false);
    const [showMarkArrivedModal, setShowMarkArrivedModal] = useState(false);
    const [markArrivedFormData, setMarkArrivedFormData] = useState({
        qtyArrived: 0
    });

    const handleClose = () => setShowMarkArrivedModal(false);
    const handleShow = (inboundItem) => {
        if (!hasPermission(userDetails, "In-stock", "Add")) {
            setErrors({ message: 'You do not have permission to perform this action.' });
            return;
        }

        setErrors({});
        setMarkArrivedFormData({
            qtyArrived: inboundItem.inbound_qty,
            itemId: inboundItem.itemId,
            itemType: inboundItem.type,
            cog: inboundItem.cog || 0,
            originalQtyArriving: inboundItem.inbound_qty
        });
        setShowMarkArrivedModal(true);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setMarkArrivedFormData({
            ...markArrivedFormData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (markArrivedFormData.qtyArrived <= 0) {
            setErrors({
                qtyArrived: 'Please enter a valid Quantity'
            });
            return;
        }

        if (markArrivedFormData.qtyArrived > markArrivedFormData.originalQtyArriving) {
            setErrors({
                qtyArrived: 'Quantity arrived cannot be greater than quantity inbound'
            });
            return;
        }

        setMarkArrivedLoading(true);

        try {
            const response = await axios.post(`${serverEndpoint}/inbound/mark-item-arrived`, {
                email: userDetails.username,
                itemId: markArrivedFormData.itemId,
                type: markArrivedFormData.itemType,
                qtyArrived: markArrivedFormData.qtyArrived,
                cog: markArrivedFormData.cog
            }, { withCredentials: true });
            if (response.data.newInboundQty > 0) {
                setInboundItemsData(prevItems =>
                    prevItems.map(item =>
                        item.itemId === markArrivedFormData.itemId
                            ? { ...item, inbound_qty: response.data.newInboundQty }
                            : item
                    )
                );
            } else if (response.data.newInboundQty <= 0) {
                setInboundItemsData(prevItems =>
                    prevItems.filter(item => item.itemId !== markArrivedFormData.itemId)
                );
            }
            setLoading(false);
        } catch (error) {
            console.log(error);
            setErrors({ message: 'Something went wrong, please try again' });
            setLoading(false);
        }

        setMarkArrivedLoading(false);
        handleClose();
    };

    if (loading) {
        return <Spinner />;
    }

    if (errors?.fatal) {
        return <ErrorComponent />;
    }

    return (
        <>
            <section>
                <div className="container bg-white p-4 rounded border">
                    <div className="">
                        {errors.message && (
                            <div className="alert alert-danger" role="alert">
                                {errors.message}
                            </div>
                        )}
                        <h2>Inbound Items</h2>
                        <p className="text-secondary mb-2">View all your inbound items including Components and Listings</p>
                    </div>
                    <hr className="text-muted" />
                    <div className="my-3">
                        <Form.Control
                            type="text"
                            placeholder="Search by title or Description"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        {itemsLoading && (
                            <div className="d-flex justify-content-center mt-4">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Item display */}
                    <Row className="mt-2 text-center">
                        {displayData.length === 0 && !itemsLoading && (
                            <div className="d-flex justify-content-center mt-4">
                                <p>No results found</p>
                            </div>
                        )}

                        {displayData.map((inboundItem) => (
                            <Col xs={12} md={6} lg={3} className="d-flex px-2 my-2" key={inboundItem.itemId}>
                                <Card className="position-relative h-100 d-flex flex-column">
                                    <Card.Body className="d-flex justify-content-center align-items-center px-4">
                                        <Link to={inboundItem.url} >
                                            <img src={inboundItem.image_url ? inboundItem.image_url : '/images/default-image.jpg'} alt="..." className="rounded"
                                                onError={(e) => {
                                                    e.target.onerror = null; // Prevent infinite loop if fallback image fails
                                                    e.target.src = "/images/default-image.jpg";
                                                }}
                                                style={{
                                                    maxHeight: '210px',
                                                    width: '100%',
                                                    objectFit: 'cover'
                                                }} />
                                        </Link>
                                    </Card.Body>
                                    <Card.Body className="flex-grow-1 pt-0">
                                        <Link to={inboundItem.url} className="text-body text-decoration-none hover:text-primary">
                                            <h5 className="w-100" title={inboundItem.title}>
                                                {inboundItem.title.length > 20 ? `${inboundItem.title.substring(0, 20)}...` : inboundItem.title}
                                            </h5>
                                        </Link>
                                    </Card.Body>
                                    <div className="mb-4">
                                        <button className="btn btn-outline-primary" onClick={() => handleShow(inboundItem)}>
                                            <span className="badge bg-secondary mx-1">{inboundItem.inbound_qty}</span> Mark Arrived
                                        </button>
                                    </div>
                                </Card>
                            </Col>
                        ))}
                    </Row>

                    {paginationKey && searchQuery.length === 0 && (
                        <div className="row justify-content-center mt-4">
                            <div className="col-auto">

                                {itemsLoading ? (
                                    <button className="btn btn-primary" type="button" disabled="">
                                        <span
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </button>
                                ) : (
                                    <button onClick={() => getInboundItems()} className="btn btn-primary">
                                        Load More
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </section>

            <Modal show={showMarkArrivedModal} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Enter Arrived Quantity</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label className="mb-2 text-muted" htmlFor="qtyArrived">
                                Quantity Arrived
                            </label>
                            <input id="qtyArrived" type="number" className={errors?.qtyArrived ? 'form-control is-invalid' : 'form-control'}
                                name="qtyArrived" value={markArrivedFormData.qtyArrived} required autoFocus onChange={handleChange}
                            />
                            <div className="invalid-feedback">Please provide a valid Quantity</div>
                        </div>

                        <div className="align-items-center d-flex">
                            {markArrivedLoading && (
                                <button className="btn btn-primary ms-auto" type="button" disabled>
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </button>
                            )}

                            {!markArrivedLoading && (
                                <button className="btn btn-primary ms-auto" type="submit">
                                    Submit
                                </button>
                            )}
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ViewInboundItems;
