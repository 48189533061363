import Analytics from "./Analytics";
import { useSelector } from 'react-redux';
import { hasPermission } from "../../util/permissionsEvaluator";
import { Container, Row, Col } from 'react-bootstrap';

function Dashboard() {
    const userDetails = useSelector((state) => state.userDetails);

    if (!hasPermission(userDetails, "Reporting", "View")) {
        return (
            <Container className="my-5 bg-white border rounded p-3">
                <Row className="justify-content-center">
                    <Col md={12}>
                        <h2 className="">Business Metrics</h2>
                        <hr />
                        <p className="text-muted">
                            You do not have the necessary permissions to view this page.
                            <br />
                            If you believe this is an error, please contact the system administrator or log in with the correct account.
                            <br/>
                            <br/>
                            You can perform other actions by using the links in the sidebar or header.
                        </p>

                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <Analytics />
    );
}

export default Dashboard;