import axios from "axios";
import { useEffect, useState } from "react";
import { FcMoneyTransfer } from "react-icons/fc";
import { serverEndpoint } from "../../util/config";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import Spinner from "../../components/Spinner";

function ManageReferral() {
    const dispatch = useDispatch();
    const userDetails = useSelector((state) => state.userDetails);
    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState(null);
    const [isEditable, setIsEditable] = useState(false);
    const [addMode, setAddMode] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [buttonText, setButtonText] = useState("Copy");
    const [buttonVariant, setButtonVariant] = useState("outline-primary");
    const [formData, setFormData] = useState({
        referrerCode: ''
    });
    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(true);

    const getReferralDetails = async () => {
        if (userDetails.referrerCode && userDetails.referrerCode.length > 0) {
            const response = await axios.get(`${serverEndpoint}/referral/get-referrer/${userDetails.referrerCode}`, { withCredentials: true });
            if (!response.data?.referrer?.code_status || response.data?.referrer?.code_status !== 'REDEEMED') {
                setIsEditable(true);
            }
        } else {
            setAddMode(true);
        }
        setPageLoading(false);
    };

    useEffect(() => {
        if (userDetails.hasTrialEnded) {
            getReferralDetails();
        } else {
            setPageLoading(false);
        }
    }, []);

    const handleCopyClick = () => {
        navigator.clipboard
            .writeText(userDetails.referrerCode)
            .then(() => {
                setButtonText("Copied!");
                setButtonVariant("outline-success");
                setTimeout(() => {
                    setButtonText("Copy");
                    setButtonVariant("outline-primary");
                }, 3000);
            })
            .catch((err) => {
                setButtonText("Try again!");
                setButtonVariant("outline-danger");
                setTimeout(() => {
                    setButtonText("Copy");
                    setButtonVariant("outline-primary");
                }, 3000);
            });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleAddReferralCode = async (e) => {
        e.preventDefault();
        setErrors({});

        if (formData.referrerCode.length > 20) {
            setErrors({
                referrerCode: 'Referral Code cannot be more than 20 characters'
            });
            return;
        }

        const spaceRegex = /\s/;
        if (spaceRegex.test(formData.referrerCode)) {
            setErrors({
                referrerCode: 'Referral Code cannot contain space character'
            });
            return;
        }

        setLoading(true);
        try {
            const response = await axios.get(`${serverEndpoint}/referral/get-referrer/${formData.referrerCode}`, { withCredentials: true });
            if (response.data.referrer) {
                setErrors({ referrerCode: 'Referral code already exist!' });
                return;
            }

            await axios.post(`${serverEndpoint}/referral/create-referral-code`, {
                referral_code: formData.referrerCode
            }, { withCredentials: true });

            setMessage("Referral code added successfully");
            setIsEditable(true);
            setAddMode(false);
            setEditMode(false);
            dispatch({
                type: 'setUserDetails', userDetails: {
                    ...userDetails,
                    referrerCode: formData.referrerCode,
                }
            });
        } catch (error) {

        } finally {
            setLoading(false);
        }
    };

    const handleEdit = () => {
        setFormData({
            referrerCode: userDetails.referrerCode
        });
        setEditMode(true);
    };

    const handleEditClose = () => {
        setEditMode(false);
    };

    if (pageLoading) {
        return <Spinner />;
    }

    if (!userDetails.hasTrialEnded) {
        return (
            <div className="container p-3 rounded border bg-white">
                <div className="row">
                    <div className="col-12">
                        <div className="">
                            <h2><FcMoneyTransfer /> Refer and Earn! <FcMoneyTransfer /></h2>
                            <p className="text-secondary mb-2">
                                Give your friend 10% off forever on their Prep Master subscription.
                                In return, you'll earn 10% (or more) of every payment they make,
                                as long as they remain a member.
                                Just ask them to use the referral code during signup!
                            </p>
                        </div>
                        <hr className="text-muted" />
                        <div className="row">
                            <div className="col">
                                <p className="text-warning">You must have an active subscription to participate in the referral program.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="container p-3 rounded border bg-white">
            <div className="row">
                <div className="col-12">
                    <div className="">
                        <h2><FcMoneyTransfer /> Refer and Earn! <FcMoneyTransfer /></h2>
                        <p className="text-secondary mb-2">
                            Give your friend 10% off forever on their Prep Master subscription.
                            In return, you'll earn 10% (or more) of every payment they make,
                            as long as they remain a member.
                            Just ask them to use the referral code during signup!
                        </p>
                    </div>
                    <hr className="text-muted" />

                    {errors.message && (
                        <div className="alert alert-danger" role="alert">
                            {errors.message}
                        </div>
                    )}

                    {message && (
                        <div className="alert alert-success" role="alert">
                            {message}
                        </div>
                    )}

                    {addMode && (
                        <>
                            <div className="row">
                                <div className="col">
                                    <h4>Add referral code to get started</h4>
                                </div>
                            </div>
                            <form>
                                <div className="row">
                                    <div className="col-lg-7 col-12 mt-1">
                                        <input id="referrerCode" type="text" className={errors.referrerCode ? 'form-control is-invalid' : 'form-control'}
                                            name="referrerCode" value={formData.referrerCode} autoFocus onChange={handleChange}
                                            placeholder="Enter personalized referral code"
                                        />
                                        <div className="invalid-feedback">{errors?.referrerCode}</div>
                                    </div>
                                    <div className="col-lg-5 col-12 mt-1">
                                        {loading && (
                                            <button className="btn btn-primary ms-auto" type="button" disabled="">
                                                <span
                                                    className="spinner-border spinner-border-sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            </button>
                                        )}

                                        {!loading && (
                                            <button className="btn btn-primary ms-auto" type="button" onClick={handleAddReferralCode}>
                                                Submit
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </>
                    )}

                    {!addMode && (
                        <>
                            <div className="row">
                                <div className="col">
                                    <h4>Copy and share your referral code</h4>
                                </div>
                            </div>
                            <form>
                                <div className="row">
                                    <div className="col-lg-7 col-12 mt-1">
                                        <input id="referrerCode" type="text" className={errors.referrerCode ? 'form-control is-invalid' : 'form-control'}
                                            name="referrerCode" value={!editMode ? userDetails.referrerCode : formData.referrerCode} autoFocus onChange={handleChange}
                                            disabled={!editMode}
                                        />
                                        <div className="invalid-feedback">{errors?.referrerCode}</div>
                                    </div>
                                    <div className="col-lg-5 col-12 mt-1">
                                        {!editMode && (
                                            <Button onClick={handleCopyClick} variant={buttonVariant}>
                                                {buttonText}
                                            </Button>
                                        )}
                                        {isEditable && !editMode && (
                                            <Button onClick={handleEdit} className="mx-1" variant="outline-success">
                                                Edit
                                            </Button>
                                        )}
                                        {editMode && (
                                            <>
                                                {loading && (
                                                    <>
                                                        <button className="btn btn-primary" type="button" disabled="">
                                                            <span
                                                                className="spinner-border spinner-border-sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                        </button>
                                                        <button className="btn btn-success mx-1 text-white" type="button" disabled="">
                                                            <span
                                                                className="spinner-border spinner-border-sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            />
                                                        </button>
                                                    </>
                                                )}

                                                {!loading && (
                                                    <>
                                                        <button className="btn btn-primary" type="button" onClick={handleAddReferralCode}>
                                                            Save
                                                        </button>
                                                        <button className="btn btn-success mx-1 text-white" type="button" onClick={handleEditClose}>
                                                            Close
                                                        </button>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </>
                    )}

                </div>
            </div>
        </div>
    );
}

export default ManageReferral;