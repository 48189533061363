import axios from "axios";
import { useEffect, useState } from "react";
import Spinner from "../../components/Spinner";
import { serverEndpoint, CURRENCY_TO_SYMBOL_MAP } from "../../util/config";
import { Card, Col, Row, Modal, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { BsTelephoneInbound } from "react-icons/bs";
import { LuListTodo } from "react-icons/lu";
import ErrorComponent from "../../components/Error";
import { useSelector } from 'react-redux';
import { hasPermission } from "../../util/permissionsEvaluator";
import { DEFAULT_PAGE_SIZE } from "../../util/constants";

function ViewBundles() {
    const userDetails = useSelector((state) => state.userDetails);
    const currencySymbol = CURRENCY_TO_SYMBOL_MAP[userDetails.accountCurrency] || '£';
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});
    const [bundlesData, setBundlesData] = useState([]);
    const [displayData, setDisplayData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [paginationKey, setPaginationKey] = useState(null);
    const [itemsLoading, setItemsLoading] = useState(false);
    const [localDataAvailable, setLocalDataAvailable] = useState(false);

    const getBundles = async () => {
        if (itemsLoading || localDataAvailable) return;

        try {
            setItemsLoading(true);
            const params = {
                limit: DEFAULT_PAGE_SIZE
            };
            if (paginationKey) {
                params.lastEvaluatedKey = paginationKey;
            }

            const response = await axios.get(`${serverEndpoint}/bundles/get-bundles`, {
                params: params,
                withCredentials: true,
            });
            setBundlesData((prev) => [...prev, ...response.data.items]);
            setDisplayData((prev) => [...prev, ...response.data.items]);
            setPaginationKey(response.data.lastEvaluatedKey);
            if (!response.data.lastEvaluatedKey) setLocalDataAvailable(true);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setErrors({ fatal: 'Something went wrong, please try again!' });
            setLoading(false);
        } finally {
            setItemsLoading(false);
        }
    };

    useEffect(() => {
        getBundles();
    }, []);


    const getSearchResults = async () => {
        try {
            setItemsLoading(true);
            const params = {
                limit: DEFAULT_PAGE_SIZE
            };
            if (paginationKey) {
                params.lastEvaluatedKey = paginationKey;
            }

            const response = await axios.get(`${serverEndpoint}/bundles/get-bundles/${searchQuery}`, { withCredentials: true, });
            setDisplayData(response.data.items);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setErrors({ fatal: 'Something went wrong, please try again!' });
            setLoading(false);
        } finally {
            setItemsLoading(false);
        }
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchQuery.trim() === "") {
                setDisplayData([...bundlesData]); // Reset to full data if search term is empty
            } else {
                const searchResults = paginationKey === null
                    ? bundlesData.filter((item) =>
                        item.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        item.description?.toLowerCase().includes(searchQuery.toLowerCase())
                    ) // Local search
                    : null;

                if (searchResults !== null) {
                    setDisplayData(searchResults);
                } else {
                    getSearchResults();
                }
            }
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [searchQuery, bundlesData]);

    const [markInboundLoading, setMarkInboundLoading] = useState(false);
    const [showMarkInboundModal, setShowMarkInboundModal] = useState(false);
    const [markInboundFormData, setMarkInboundFormData] = useState({
        listings: {},
        components: {}
    });

    const handleClose = () => setShowMarkInboundModal(false);
    const handleShow = (inboundItem) => {
        if (!hasPermission(userDetails, "Inbound", "Add")) {
            setErrors({ message: 'You do not have permission to perform this action.' });
            return;
        }

        setErrors({});

        const initializedListings = {};
        const initializedComponents = {};

        Object.entries(inboundItem.listings).forEach(([id, listing]) => {
            initializedListings[id] = {
                ...listing,
                inboundQty: 0 // Set default input value to 0
            };
        });

        Object.entries(inboundItem.components).forEach(([id, component]) => {
            initializedComponents[id] = {
                ...component,
                inboundQty: 0 // Set default input value to 0
            };
        });

        setMarkInboundFormData({
            listings: initializedListings,
            components: initializedComponents
        });
        setShowMarkInboundModal(true);
    }

    const handleInputChange = (id, value, type, data) => {
        setMarkInboundFormData(prevState => ({
            ...prevState,
            [type]: {
                ...prevState[type],
                [id]: {
                    ...data, // Include the full listing/component data
                    inboundQty: value // Store the user input value
                }
            }
        }));
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};
        for (let [id, listing] of Object.entries(markInboundFormData.listings)) {
            if (!listing.inboundQty || listing.inboundQty <= 0) {
                newErrors[id] = 'Please enter a value greater than 0';
                isValid = false;
            }
        }

        for (let [id, component] of Object.entries(markInboundFormData.components)) {
            if (!component.inboundQty || component.inboundQty <= 0) {
                newErrors[id] = 'Please enter a value greater than 0';
                isValid = false;
            }
        }
        setErrors(newErrors);
        return isValid;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setMarkInboundLoading(true);
            axios.post(`${serverEndpoint}/inbound/create`, markInboundFormData, { withCredentials: true })
                .then(response => {
                    setMarkInboundLoading(false);
                    handleClose();
                }).catch(error => {
                    console.log(error);
                    setErrors({ message: "Unable to add Bundle, please try again!" });
                });
        }
    };


    const [addToDoLoading, setAddTodoLoading] = useState(false);
    const [showAddToDoModal, setShowAddToDoModal] = useState(false);
    const [addToDoFormData, setAddToDoFormData] = useState({
        quantityToPrepare: 0
    });

    const handleAddToDoClose = () => setShowAddToDoModal(false);
    const handleAddToDoShow = (todoItem) => {
        if (!hasPermission(userDetails, "Task Management", "Add")) {
            setErrors({ message: 'You do not have permission to perform this action.' });
            return;
        }
        setErrors({});
        setAddToDoFormData({
            quantityToPrepare: 0,
            itemToPrepare: todoItem
        });
        setShowAddToDoModal(true);
    }

    const handleAddTodoFormChange = (e) => {
        const { name, value } = e.target;
        setAddToDoFormData({
            ...addToDoFormData,
            [name]: value,
        });
    };

    const handleAddTodoSubmit = async (e) => {
        e.preventDefault();
        if (addToDoFormData.quantityToPrepare <= 0) {
            setErrors({
                quantityToPrepare: 'Please enter a valid Quantity'
            });
            return;
        }

        setAddTodoLoading(true);
        for (const key in addToDoFormData.itemToPrepare.listings) {
            delete addToDoFormData.itemToPrepare.listings[key].image_url;
        }
        for (const key in addToDoFormData.itemToPrepare.components) {
            delete addToDoFormData.itemToPrepare.components[key].image_url;
        }
        axios.post(`${serverEndpoint}/tasks/create-task`, {
            title: addToDoFormData.itemToPrepare.title,
            quantity_to_prepare: addToDoFormData.quantityToPrepare,
            listings: addToDoFormData.itemToPrepare.listings ? addToDoFormData.itemToPrepare.listings : {},
            components: addToDoFormData.itemToPrepare.components ? addToDoFormData.itemToPrepare.components : {},
            bundles: {},
            image_key: addToDoFormData.itemToPrepare.image_key,
            item_id: addToDoFormData.itemToPrepare.sk,
        }, { withCredentials: true })
            .then(response => {
                setAddTodoLoading(false);
                handleAddToDoClose();
            }).catch(error => {
                console.log(error);
                handleAddToDoClose();
                setErrors({ message: "Unable to create ToDo task!" });
            });
    };


    if (loading) {
        return <Spinner />;
    }

    if (errors?.fatal) {
        return <ErrorComponent />;
    }

    return (
        <>
            <section>
                <div className="container bg-white p-4 rounded border">
                    <div className="">
                        {errors.message && (
                            <div className="alert alert-danger" role="alert">
                                {errors.message}
                            </div>
                        )}
                        <h2>Bundles</h2>
                        <p className="text-secondary mb-2">View & Manage all your Bundles</p>
                    </div>
                    <hr className="text-muted" />
                    <div className="my-3 text-center">
                        <Form.Control
                            type="text"
                            placeholder="Search by title or Description"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        {itemsLoading && (
                            <div className="d-flex justify-content-center mt-4">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Item display */}
                    <Row className="mt-2 text-center">
                        {displayData.length === 0 && !itemsLoading && (
                            <div className="d-flex justify-content-center mt-4">
                                <p>No results found</p>
                            </div>
                        )}

                        {displayData.map((bundle) => (
                            <Col xs={12} md={6} lg={3} className="d-flex px-2 my-2" key={bundle.sk}>
                                <Card className="position-relative h-100 d-flex flex-column">
                                    <Card.Body className="d-flex justify-content-center align-items-center px-4">
                                        <Link to={`/bundle/${bundle.sk.split('B#')[1]}`}>
                                            <img src={bundle.image_url ? bundle.image_url : '/images/default-image.jpg'}
                                                onError={(e) => {
                                                    e.target.onerror = null; // Prevent infinite loop if fallback image fails
                                                    e.target.src = "/images/default-image.jpg";
                                                }}
                                                alt={bundle.title} className="rounded" style={{
                                                    maxHeight: '210px',
                                                    width: '100%',
                                                    objectFit: 'cover'
                                                }} />
                                        </Link>
                                    </Card.Body>
                                    <Card.Body className="flex-grow-1 pt-0">
                                        <Link to={`/bundle/${bundle.sk.split('B#')[1]}`} className="text-body text-decoration-none hover:text-primary">
                                            <h5 className="w-100" title={bundle.title}>
                                                {bundle.title.length > 20 ? `${bundle.title.substring(0, 20)}...` : bundle.title}
                                            </h5>
                                        </Link>
                                        <h5 className="text-primary"><strong>{currencySymbol}{bundle.sale_price}</strong></h5>
                                    </Card.Body>
                                    <div className="d-flex justify-content-center mb-4">
                                        <div className="mx-2">
                                            <button className="btn btn-sm btn-outline-success" onClick={() => handleShow(bundle)}>
                                                <BsTelephoneInbound size={15} /> Mark Inbound
                                            </button>
                                        </div>
                                        <div className="mx-2">
                                            <button className="btn btn-sm btn-outline-info" onClick={() => handleAddToDoShow(bundle)}>
                                                <LuListTodo size={18} className="mb-0" /> Add to ToDo
                                            </button>
                                        </div>
                                    </div>

                                </Card>
                            </Col>
                        ))}
                    </Row>
                    {paginationKey && searchQuery.length === 0 && (
                        <div className="row justify-content-center mt-4">
                            <div className="col-auto">

                                {itemsLoading ? (
                                    <button className="btn btn-primary" type="button" disabled="">
                                        <span
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                    </button>
                                ) : (
                                    <button onClick={() => getBundles()} className="btn btn-primary">
                                        Load More
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </section>

            <Modal show={showMarkInboundModal} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Enter Inbound Quantity</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {errors.message && (
                        <div className="alert alert-danger" role="alert">
                            {errors.message}
                        </div>
                    )}
                    <form onSubmit={handleSubmit}>
                        {Object.entries(markInboundFormData.listings).map(([id, listing]) => (
                            <div key={id} className="mb-3">
                                <label className="mb-2 text-muted">
                                    {listing.title}
                                </label>
                                <input type="number" className={errors[id] ? 'form-control is-invalid' : 'form-control'}
                                    value={markInboundFormData.listings[id]?.inboundQty || ''}
                                    onChange={(e) => handleInputChange(id, e.target.value, 'listings', listing)}
                                />
                                <div className="invalid-feedback">Please provide a valid Quantity</div>
                            </div>
                        ))}

                        {Object.entries(markInboundFormData.components).map(([id, component]) => (
                            <div key={id} className="mb-3">
                                <label className="mb-2 text-muted">
                                    {component.title}
                                </label>
                                <input type="number" className={errors[id] ? 'form-control is-invalid' : 'form-control'}
                                    value={markInboundFormData.components[id]?.inboundQty || ''}
                                    onChange={(e) => handleInputChange(id, e.target.value, 'components', component)}
                                />
                                <div className="invalid-feedback">Please provide a valid Quantity</div>
                            </div>
                        ))}

                        <div className="align-items-center d-flex">
                            {markInboundLoading && (
                                <button className="btn btn-primary ms-auto" type="button" disabled>
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </button>
                            )}

                            {!markInboundLoading && (
                                <button className="btn btn-primary ms-auto" type="submit">
                                    Submit
                                </button>
                            )}
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showAddToDoModal} onHide={handleAddToDoClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Create ToDo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleAddTodoSubmit}>
                        <div className="mb-3">
                            <label className="mb-2 text-muted" htmlFor="quantityToPrepare">
                                Quantity to Prepare
                            </label>
                            <input id="quantityToPrepare" type="number" className={errors?.quantityToPrepare ? 'form-control is-invalid' : 'form-control'}
                                name="quantityToPrepare" value={addToDoFormData.quantityToPrepare} autoFocus onChange={handleAddTodoFormChange}
                            />
                            <div className="invalid-feedback">Please provide a valid Quantity</div>
                        </div>

                        <div className="align-items-center d-flex">
                            {addToDoLoading && (
                                <button className="btn btn-primary ms-auto" type="button" disabled>
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </button>
                            )}

                            {!addToDoLoading && (
                                <button className="btn btn-primary ms-auto" type="submit">
                                    Create
                                </button>
                            )}
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ViewBundles;