import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function ErrorComponent() {
    return (
        <Container className="text-center my-5">
            <Row className="justify-content-center">
                <Col md={6}>
                    <h1 className="display-4 text-danger">Oops!</h1>
                    <p className="text-muted">Something went wrong, please try again or refresh the page!</p>
                </Col>
            </Row>
        </Container>
    );
}

export default ErrorComponent;