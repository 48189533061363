import { Container, Row, Col } from 'react-bootstrap';

function UnauthorizedAccess() {
    return (
        <Container className="my-5 bg-white border rounded p-3">
            <Row className="justify-content-center">
                <Col md={12}>
                    <h2 className="text-danger">Unauthorized Access</h2>
                    <hr />
                    <p className="text-muted">
                        You do not have the necessary permissions to view this page.
                        <br />
                        If you believe this is an error, please contact the system administrator or log in with the correct account.
                    </p>

                </Col>
            </Row>
        </Container>
    );
}

export default UnauthorizedAccess;