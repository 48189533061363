import { useEffect, useState } from "react";
import { CURRENCY_TO_SYMBOL_MAP, serverEndpoint } from "../../util/config";
import axios from "axios";
import { FaChartLine } from "react-icons/fa";
import { GiProfit } from "react-icons/gi";
import { MdFileDownload, MdFileUpload, MdPriceCheck } from "react-icons/md";
import { GrBundle } from "react-icons/gr";
import { LuComponent } from "react-icons/lu";
import { LiaSitemapSolid } from "react-icons/lia";
import { BsTelephoneInbound } from "react-icons/bs";
import { FaTasks } from "react-icons/fa";
import { FaUsersViewfinder } from "react-icons/fa6";
import { ThreeDot } from "react-loading-indicators";
import ErrorComponent from "../../components/Error";
import { IoPricetagsOutline } from "react-icons/io5";
import { useSelector } from 'react-redux';

function Analytics() {
    const userDetails = useSelector((state) => state.userDetails);
    const currencySymbol = CURRENCY_TO_SYMBOL_MAP[userDetails.accountCurrency] || '£';
    const [analyticsData, setAnalyticsData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({
        filter: "7",
        startDate: "",
        endDate: ""
    });
    const [showCustomFilter, setShowCustomFilter] = useState(false);
    const [errors, setErrors] = useState({});

    const handleFetch = async () => {
        let startDate = formData.startDate;
        let endDate = formData.endDate;
        if (formData.filter !== 'custom') {
            const days = parseInt(formData.filter);
            endDate = getDate(0);
            startDate = getDate(days);
        }

        try {
            setLoading(true);
            const response = await axios.get(`${serverEndpoint}/analytics/get-summary/${startDate}/${endDate}`, { withCredentials: true });
            setAnalyticsData({
                ...analyticsData,
                summary: response.data.summary
            });
        } catch (error) {
            setErrors({ message: 'Unable to fetch requested analytics, please try again' });
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const getDate = (minusDays) => {
        const today = new Date();
        today.setDate(today.getDate() - minusDays);
        const yyyy = today.getFullYear();
        const mm = String(today.getMonth() + 1).padStart(2, '0');
        const dd = String(today.getDate()).padStart(2, '0');
        return `${yyyy}-${mm}-${dd}`;
    };

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });

        if (e.target.name === "filter" && e.target.value === "custom") {
            setShowCustomFilter(true);
        } else if (e.target.name === "filter" && e.target.value !== "custom") {
            setShowCustomFilter(false);
        }
    };

    const getAnalytics = async () => {
        try {
            const todayDate = getDate(0);
            const todayMinusXDaysDate = getDate(7);
            const response = await axios.get(`${serverEndpoint}/analytics/get/${todayMinusXDaysDate}/${todayDate}`, { withCredentials: true });
            setAnalyticsData(response.data);
        } catch (error) {
            console.log(error);
            setErrors({ fatal: 'Something went wrong!' })
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getAnalytics();
    }, []);

    useEffect(() => {
        const todayDate = getDate(0);
        const todayMinusXDaysDate = getDate(7);
        const initialFormData = {
            startDate: todayMinusXDaysDate,
            endDate: todayDate,
            filter: '7'
        };
        setFormData(initialFormData);
    }, []);

    if (errors.fatal) {
        return <ErrorComponent />;
    }

    return (
        <div className="container-fluid">
            {errors.message && (
                <div className="alert alert-danger" role="alert">
                    {errors.message}
                </div>
            )}
            <h3 className="">Business Metrics</h3>
            <hr />
            <div className="header-body bg-white p-3 rounded border">
                <div className="row">
                    <form>
                        <div className="row">
                            <div className="col-md-4 mb-2">
                                <div className="form-floating">
                                    <select className="form-control" id="filter" name="filter" value={formData.filter} onChange={handleInputChange}>
                                        <option value='7'>Last 7 Days</option>
                                        <option value='14'>Last 14 Days</option>
                                        <option value='30'>Last 30 Days</option>
                                        <option value='custom'>Custom</option>
                                    </select>
                                    <label htmlFor="filter">Filter</label>
                                </div>
                            </div>
                            {showCustomFilter && (
                                <>
                                    <div className="col-md-4 mb-2">
                                        <div className="form-floating">
                                            <input
                                                type="date"
                                                className="form-control"
                                                id="startDate"
                                                name="startDate"
                                                defaultValue={formData.startDate}
                                                onChange={handleInputChange}
                                            />
                                            <label htmlFor="startDate">Start Date:</label>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mb-2">
                                        <div className="form-floating">
                                            <input
                                                type="date"
                                                className="form-control"
                                                id="endDate"
                                                name="endDate"
                                                defaultValue={formData.endDate}
                                                onChange={handleInputChange}
                                            />
                                            <label htmlFor="endDate">End Date:</label>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="row mt-2">
                            <div className="col-md-3 mb-2">
                                <div className="form-floating">
                                    <button type="button" className="btn btn-primary" onClick={handleFetch} disabled={loading ? true : false}>
                                        Apply
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className="header-body bg-white p-3 rounded border mt-3">
                <div className="row mt-3">
                    <div className="col-xl-6 col-lg-6">
                        <div className="card card-stats mb-4 mb-xl-0">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h6 className="card-title text-uppercase text-muted mb-0">
                                            Sales
                                        </h6>
                                        <span className="h2 font-weight-bold mb-0">
                                            {loading ? (
                                                <ThreeDot size="small" color="#000" />
                                            ) : (
                                                <>{currencySymbol}{analyticsData.summary.sales}</>
                                            )}
                                        </span>
                                    </div>
                                    <div className="col-auto">
                                        <FaChartLine size={50} className="text-success" />
                                    </div>
                                </div>
                                <p className="mt-3 mb-0 text-muted text-sm">
                                    <span className="text-success mr-2">
                                        {loading ? (
                                            <ThreeDot size="small" color="#198754" />
                                        ) : (
                                            <>{currencySymbol}{analyticsData.summary.sales_avg}</>
                                        )}
                                    </span>
                                    <span className="text-nowrap"> Average Sale</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6">
                        <div className="card card-stats mb-4 mb-xl-0">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h6 className="card-title text-uppercase text-muted mb-0">
                                            Profit
                                        </h6>
                                        <span className="h2 font-weight-bold mb-0">
                                            {loading ? (
                                                <ThreeDot size="small" color="#000" />
                                            ) : (
                                                <>{currencySymbol}{analyticsData.summary.profit}</>
                                            )}
                                        </span>
                                    </div>
                                    <div className="col-auto">
                                        <GiProfit size={50} className="text-primary" />
                                    </div>
                                </div>
                                <p className="mt-3 mb-0 text-muted text-sm">
                                    <span className="text-success mr-2">
                                        {loading ? (
                                            <ThreeDot size="small" color="#198754" />
                                        ) : (
                                            <>{currencySymbol}{analyticsData.summary.profit_avg}</>
                                        )}
                                    </span>
                                    <span className="text-nowrap"> Average Profit</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-xl-6 col-lg-6">
                        <div className="card card-stats mb-4 mb-xl-0">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h6 className="card-title text-uppercase text-muted mb-0">
                                            Inbound COG
                                        </h6>
                                        <span className="h2 font-weight-bold mb-0">
                                            {loading ? (
                                                <ThreeDot size="small" color="#000" />
                                            ) : (
                                                <>{currencySymbol}{analyticsData.stats.inboundItemsTotalCog}</>
                                            )}
                                        </span>
                                    </div>
                                    <div className="col-auto">
                                        <IoPricetagsOutline size={50} className="text-success" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6">
                        <div className="card card-stats mb-4 mb-xl-0">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h6 className="card-title text-uppercase text-muted mb-0">
                                            Warehouse COG
                                        </h6>
                                        <span className="h2 font-weight-bold mb-0">
                                            {loading ? (
                                                <ThreeDot size="small" color="#000" />
                                            ) : (
                                                <>{currencySymbol}{analyticsData.stats.warehouseItemsTotalCog}</>
                                            )}
                                        </span>
                                    </div>
                                    <div className="col-auto">
                                        <MdPriceCheck size={50} className="text-primary" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-xl-3 col-lg-6">
                        <div className="card card-stats mb-4 mb-xl-0">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h6 className="card-title text-uppercase text-muted mb-0">
                                            Items Shipped Out
                                        </h6>
                                        <span className="h2 font-weight-bold mb-0">
                                            {loading ? (
                                                <ThreeDot size="small" color="#000" />

                                            ) : (
                                                <>{analyticsData.summary.itemsShippedOut}</>
                                            )}
                                        </span>
                                    </div>
                                    <div className="col-auto">
                                        <MdFileUpload size={35} className="text-success" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6">
                        <div className="card card-stats mb-4 mb-xl-0">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h6 className="card-title text-uppercase text-muted mb-0">
                                            Units Shipped Out
                                        </h6>
                                        <span className="h2 font-weight-bold mb-0">
                                            {loading ? (
                                                <ThreeDot size="small" color="#000" />

                                            ) : (
                                                <>{analyticsData.summary.unitsShippedOut}</>
                                            )}
                                        </span>
                                    </div>
                                    <div className="col-auto">
                                        <MdFileUpload size={35} className="text-success" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6">
                        <div className="card card-stats mb-4 mb-xl-0">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h6 className="card-title text-uppercase text-muted mb-0">
                                            Items Arrived
                                        </h6>
                                        <span className="h2 font-weight-bold mb-0">
                                            {loading ? (
                                                <ThreeDot size="small" color="#000" />

                                            ) : (
                                                <>{analyticsData.summary.itemsArrived}</>
                                            )}
                                        </span>
                                    </div>
                                    <div className="col-auto">
                                        <MdFileDownload size={35} className="text-primary" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6">
                        <div className="card card-stats mb-4 mb-xl-0">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h6 className="card-title text-uppercase text-muted mb-0">
                                            Units Arrived
                                        </h6>
                                        <span className="h2 font-weight-bold mb-0">
                                            {loading ? (
                                                <ThreeDot size="small" color="#000" />

                                            ) : (
                                                <>{analyticsData.summary.unitsArrived}</>
                                            )}
                                        </span>
                                    </div>
                                    <div className="col-auto">
                                        <MdFileDownload size={35} className="text-primary" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <h3 className="mt-4">Inventory & Users</h3>
            <hr />
            <div className="header-body bg-white p-3 rounded border">
                <div className="row">
                    <div className="col-xl-3 col-lg-6">
                        <div className="card card-stats mb-4 mb-xl-0">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h6 className="card-title text-uppercase text-muted mb-0">
                                            Listings
                                        </h6>
                                        <span className="h2 font-weight-bold mb-0">
                                            {loading ? (
                                                <ThreeDot size="small" color="#000" />
                                            ) : (
                                                <>{analyticsData.stats.listingsCount}</>
                                            )}
                                        </span>
                                    </div>
                                    <div className="col-auto">
                                        <LiaSitemapSolid size={35} className="text-info" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6">
                        <div className="card card-stats mb-4 mb-xl-0">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h6 className="card-title text-uppercase text-muted mb-0">
                                            Bundles
                                        </h6>
                                        <span className="h2 font-weight-bold mb-0">
                                            {loading ? (
                                                <ThreeDot size="small" color="#000" />

                                            ) : (
                                                <>{analyticsData.stats.bundlesCount}</>
                                            )}
                                        </span>
                                    </div>
                                    <div className="col-auto">
                                        <GrBundle size={35} className="text-info" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6">
                        <div className="card card-stats mb-4 mb-xl-0">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h6 className="card-title text-uppercase text-muted mb-0">
                                            Components
                                        </h6>
                                        <span className="h2 font-weight-bold mb-0">
                                            {loading ? (
                                                <ThreeDot size="small" color="#000" />
                                            ) : (
                                                <>{analyticsData.stats.componentsCount}</>
                                            )}
                                        </span>
                                    </div>
                                    <div className="col-auto">
                                        <LuComponent size={35} className="text-info" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6">
                        <div className="card card-stats mb-4 mb-xl-0">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h6 className="card-title text-uppercase text-muted mb-0">
                                            Users
                                        </h6>
                                        <span className="h2 font-weight-bold mb-0">
                                            {loading ? (
                                                <ThreeDot size="small" color="#000" />
                                            ) : (
                                                <>{analyticsData.stats.usersCount}</>
                                            )}
                                        </span>
                                    </div>
                                    <div className="col-auto">
                                        <FaUsersViewfinder size={35} className="text-info" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-xl-3 col-lg-6">
                        <div className="card card-stats mb-4 mb-xl-0">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h6 className="card-title text-uppercase text-muted mb-0">
                                            Tasks
                                        </h6>
                                        <span className="h2 font-weight-bold mb-0">
                                            {loading ? (
                                                <ThreeDot size="small" color="#000" />
                                            ) : (
                                                <>{analyticsData.stats.tasksCount}</>
                                            )}
                                        </span>
                                    </div>
                                    <div className="col-auto">
                                        <FaTasks size={35} className="text-info" />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6">
                        <div className="card card-stats mb-4 mb-xl-0">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h6 className="card-title text-uppercase text-muted mb-0">
                                            Inbound Items
                                        </h6>
                                        <span className="h2 font-weight-bold mb-0">
                                            {loading ? (
                                                <ThreeDot size="small" color="#000" />
                                            ) : (
                                                <>{analyticsData.stats.inboundItemsCount}</>
                                            )}
                                        </span>
                                    </div>
                                    <div className="col-auto">
                                        <BsTelephoneInbound size={35} className="text-info" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-6">
                        <div className="card card-stats mb-4 mb-xl-0">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h6 className="card-title text-uppercase text-muted mb-0">
                                            Inbound Units
                                        </h6>
                                        <span className="h2 font-weight-bold mb-0">
                                            {loading ? (
                                                <ThreeDot size="small" color="#000" />
                                            ) : (
                                                <>{analyticsData.stats.inboundUnitsCount}</>
                                            )}
                                        </span>
                                    </div>
                                    <div className="col-auto">
                                        <BsTelephoneInbound size={35} className="text-info" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Analytics;