import { useState } from "react";
import axios from 'axios';
import { serverEndpoint } from "../../../util/config";
import { CCardBody } from '@coreui/react';
import { managerPermissions, viewerPermissions, staffPermissions } from "../../../util/permissionTemplates";

function UpdateUser({ userDetails, updateUserDetails }) {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        role: userDetails.role,
        permissions: userDetails.permissions,
    });

    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState(null);

    const permissionsTemplate = {
        "User Management": ["Add", "Edit", "Delete", "View"],
        "Inbound": ["Add", "Edit", "Delete", "View"],
        "In-stock": ["Add", "Edit", "Delete", "View"],
        "Component Library": ["Add", "Edit", "Delete", "View"],
        "Amazon Listing": ["Add", "Edit", "Delete", "View"],
        "Task Management": ["Add", "Edit", "Delete", "View"],
        "Reporting": ["Add", "Edit", "Delete", "View"]
    };

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        if (formData.role === 'Select') {
            newErrors.role = 'Please select a valid role for the user';
            isValid = false;
        }

        if (formData.role === 'Custom' && Object.keys(formData.permissions).length === 0) {
            newErrors.role = 'Please add some permissions for the user';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => {
            const updatedFormData = { ...prevFormData, [name]: value };

            if (name === "role") {
                if (value === 'manager') {
                    updatedFormData.permissions = { ...managerPermissions };
                } else if (value === 'viewer') {
                    updatedFormData.permissions = { ...viewerPermissions };
                } else if (value === 'staff') {
                    updatedFormData.permissions = { ...staffPermissions };
                } else if (value === 'Custom') {
                    updatedFormData.permissions = {};
                }
            }

            return updatedFormData;
        });
    };

    const handleTogglePermission = (section, action) => {
        const newPermissions = { ...formData.permissions };
        if (!newPermissions[section]) {
            newPermissions[section] = [];
        }

        if (newPermissions[section].includes(action)) {
            newPermissions[section] = newPermissions[section].filter(perm => perm !== action);
        } else {
            newPermissions[section].push(action);
        }

        setFormData({
            ...formData,
            permissions: newPermissions
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true);
            axios.post(`${serverEndpoint}/user/update-user`, {
                username: userDetails.email,
                role: formData.role,
                permissions: formData.permissions,
                phone: userDetails.phone
            }, { withCredentials: true })
                .then(response => {
                    setLoading(false);
                    updateUserDetails({
                        id: userDetails.id,
                        email: userDetails.email,
                        role: formData.role,
                        permissions: formData.permissions,
                        phone: userDetails.phone
                    });
                    setMessage('User details updated!');
                }).catch(error => {
                    console.log(error);
                    setErrors({ message: "Something went wrong, please try again!" });
                    setLoading(false);
                });
        }
    };

    const getPermissionsForRole = () => {
        switch (formData.role) {
            case 'manager':
                return managerPermissions;
            case 'viewer':
                return viewerPermissions;
            case 'staff':
                return staffPermissions;
            default:
                return {};
        }
    };

    const currentPermissions = getPermissionsForRole();

    return (
        <>
            <CCardBody>
                {errors.message && (
                    <div className="alert alert-danger" role="alert">
                        {errors.message}
                    </div>
                )}

                {message && (
                    <div className="alert alert-success" role="alert">
                        {message}
                    </div>
                )}

                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label className="mb-2 text-muted" htmlFor="email">
                            Email
                        </label>
                        <input id="email" type="text" className={errors.email ? 'form-control is-invalid' : 'form-control'}
                            name="email" placeholder="name@host.com" onChange={handleChange}
                            value={userDetails.email} autoFocus disabled
                        />
                        <div className="invalid-feedback">Please enter a valid email.</div>
                    </div>
                    <div className="mb-3">
                        <label className="mb-2 text-muted" htmlFor="phone">
                            Phone
                        </label>
                        <input id="phone" type="text" name="phone" placeholder="+440123456789"
                            className={errors.phone ? 'form-control is-invalid' : 'form-control'}
                            onChange={handleChange} value={userDetails.phone} disabled
                        />
                        <div className="invalid-feedback">Phone number must start with '+' followed by the country code and only contain digits. Remove any other characters (e.g., spaces, dashes). Format example: +14325551212.</div>
                    </div>
                    <div className="mb-3">
                        <label className="mb-2 text-muted" htmlFor="role">
                            Role
                        </label>
                        <select className={errors.role ? 'form-control is-invalid' : 'form-control'}
                            name="role" value={formData.role} onChange={handleChange}>
                            <option value="Select">Select</option>
                            <option value="manager">Manager</option>
                            <option value="staff">Staff</option>
                            <option value="viewer">Viewer</option>
                            <option value="Custom">Custom</option>
                        </select>
                        <div className="invalid-feedback">Please select a valid Role</div>
                    </div>

                    {formData.role === 'Custom' && (
                        <div className="mb-3">
                            <label className="mb-2 text-muted">Permissions</label>
                            {Object.keys(permissionsTemplate).map((section) => (
                                <div key={section} className="border p-2 m-1 rounded-2">
                                    <h6>{section}</h6>
                                    <div className="d-flex flex-wrap">
                                        {permissionsTemplate[section].map((action) => (
                                            <div className="form-check form-check-inline" key={action}>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`${section}-${action}`}
                                                    checked={formData.permissions[section]?.includes(action)}
                                                    onChange={() => handleTogglePermission(section, action)}
                                                />
                                                <label className="form-check-label" htmlFor={`${section}-${action}`}>
                                                    {action}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>

                    )}

                    {formData.role !== 'Custom' && (
                        <div className="mb-3">
                            <label className="mb-2 text-muted">Permissions</label>
                            {Object.keys(permissionsTemplate).map((section) => (
                                <div key={section} className="border p-2 m-1 rounded-2">
                                    <h6>{section}</h6>
                                    <div className="d-flex flex-wrap">
                                        {permissionsTemplate[section].map((action) => (
                                            <div className="form-check form-check-inline" key={action}>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id={`${section}-${action}`}
                                                    checked={currentPermissions[section]?.includes(action)}
                                                    disabled
                                                />
                                                <label className="form-check-label" htmlFor={`${section}-${action}`}>
                                                    {action}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    <div className="align-items-center d-flex">
                        {loading && (
                            <button className="btn btn-primary" type="button" disabled="">
                                <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </button>
                        )}

                        {!loading && (
                            <>
                                <button className="btn btn-success mx-1" type="submit">
                                    Update
                                </button>
                                <button className="btn btn-primary" onClick={() => updateUserDetails(userDetails)}>
                                    Close
                                </button>
                            </>
                        )}
                    </div>
                </form>
            </CCardBody>
        </>
    );
}

export default UpdateUser;