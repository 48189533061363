import axios from "axios";
import { useEffect, useState } from "react";
import Spinner from "../../components/Spinner";
import { serverEndpoint } from "../../util/config";
import { Card, Col, Row, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import ErrorComponent from "../../components/Error";
import { DEFAULT_PAGE_SIZE } from "../../util/constants";

function ViewInstockItems() {
    const [isComponentsView, setIsComponentsView] = useState(false);
    const switchView = (tab) => setIsComponentsView(tab);

    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});
    const [instockListingsData, setInstockListingsData] = useState([]);
    const [displayData, setDisplayData] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [paginationKey, setPaginationKey] = useState(null);
    const [itemsLoading, setItemsLoading] = useState(false);
    const [localDataAvailable, setLocalDataAvailable] = useState(false);

    const getInstockListings = async () => {
        if (itemsLoading || localDataAvailable) return;

        try {
            setItemsLoading(true);
            const params = {
                limit: DEFAULT_PAGE_SIZE,
                type: 'Listings'
            };
            if (paginationKey) {
                params.lastEvaluatedKey = paginationKey;
            }

            const response = await axios.get(`${serverEndpoint}/instock/get`, {
                params: params,
                withCredentials: true
            });
            const updatedListings = [
                ...response.data.items.map(item => ({
                    ...item,
                    url: `/listing/${item.sk.split('L#')[1]}`
                }))
            ];
            setInstockListingsData((prev) => [...prev, ...updatedListings]);
            setDisplayData((prev) => [...prev, ...updatedListings]);
            setPaginationKey(response.data.lastEvaluatedKey);
            if (!response.data.lastEvaluatedKey) setLocalDataAvailable(true);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setErrors({ fatal: 'Something went wrong, please try again!' });
            setLoading(false);
        } finally {
            setItemsLoading(false);
        }
    };

    useEffect(() => {
        getInstockListings();
    }, []);

    const getSearchResults = async () => {
        try {
            setItemsLoading(true);
            const response = await axios.get(`${serverEndpoint}/instock/get/${searchQuery}`, {
                params: {
                    type: 'Listings',
                },
                withCredentials: true,
            });
            setDisplayData(response.data.items);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setErrors({ fatal: 'Something went wrong, please try again!' });
            setLoading(false);
        } finally {
            setItemsLoading(false);
        }
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchQuery.trim() === "") {
                setDisplayData([...instockListingsData]); // Reset to full data if search term is empty
            } else {
                const searchResults = paginationKey === null
                    ? instockListingsData.filter((item) =>
                        item.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        item.description?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                        item.asin?.toLowerCase().includes(searchQuery.toLowerCase())
                    ) // Local search
                    : null;

                if (searchResults !== null) {
                    setDisplayData(searchResults);
                } else {
                    getSearchResults();
                }
            }
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [searchQuery, instockListingsData]);



    const [instockComponentsData, setInstockComponentsData] = useState([]);
    const [componentDisplayData, setComponentDisplayData] = useState([]);
    const [searchComponentQuery, setSearchComponentQuery] = useState("");
    const [componentsLoading, setComponentsLoading] = useState(false);
    const [componentsPaginationKey, setComponentsPaginationKey] = useState(null);
    const [localComponentsDataAvailable, setLocalComponentsDataAvailable] = useState(false);

    const getInstockComponents = async () => {
        if (componentsLoading || localComponentsDataAvailable) return;

        try {
            setComponentsLoading(true);
            const params = {
                limit: DEFAULT_PAGE_SIZE,
                type: 'Components'
            };
            if (paginationKey) {
                params.lastEvaluatedKey = componentsPaginationKey;
            }

            const response = await axios.get(`${serverEndpoint}/instock/get`, {
                params: params,
                withCredentials: true
            });
            const updatedComponents = [
                ...response.data.items.map(item => ({
                    ...item,
                    url: `/component/${item.sk.split('C#')[1]}`
                }))
            ];
            setInstockComponentsData((prev) => [...prev, ...updatedComponents]);
            setComponentDisplayData((prev) => [...prev, ...updatedComponents]);
            setComponentsPaginationKey(response.data.lastEvaluatedKey);
            if (!response.data.lastEvaluatedKey) setLocalComponentsDataAvailable(true);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setErrors({ fatal: 'Something went wrong, please try again!' });
            setLoading(false);
        } finally {
            setComponentsLoading(false);
        }
    };

    useEffect(() => {
        getInstockComponents();
    }, []);

    const getComponentsSearchResults = async () => {
        try {
            setComponentsLoading(true);
            const response = await axios.get(`${serverEndpoint}/instock/get/${searchComponentQuery}`, {
                params: {
                    type: 'Components',
                },
                withCredentials: true,
            });
            setComponentDisplayData(response.data.items);
            setLoading(false);
        } catch (error) {
            console.log(error);
            setErrors({ fatal: 'Something went wrong, please try again!' });
            setLoading(false);
        } finally {
            setComponentsLoading(false);
        }
    };

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (searchComponentQuery.trim() === "") {
                setComponentDisplayData([...instockComponentsData]); // Reset to full data if search term is empty
            } else {
                const searchResults = componentsPaginationKey === null
                    ? instockComponentsData.filter((item) =>
                        item.title?.toLowerCase().includes(searchComponentQuery.toLowerCase()) ||
                        item.description?.toLowerCase().includes(searchComponentQuery.toLowerCase())
                    ) // Local search
                    : null;

                if (searchResults !== null) {
                    setComponentDisplayData(searchResults);
                } else {
                    getComponentsSearchResults();
                }
            }
        }, 500);

        return () => clearTimeout(delayDebounceFn);
    }, [searchComponentQuery, instockComponentsData]);

    if (loading) {
        return <Spinner />;
    }

    if (errors?.fatal) {
        return <ErrorComponent />;
    }

    return (
        <>
            <section>
                <div className="container bg-white p-4 rounded border">
                    <div className="">
                        <h2>Instock Items</h2>
                        <p className="text-secondary mb-2">View all the components and listings that are in-stock</p>
                    </div>
                    <hr className="text-muted" />
                    <div className="text-center mb-4">
                        Listings
                        <div className="d-inline-flex align-items-center mx-2">
                            <Form.Check
                                type="switch"
                                label=""
                                checked={isComponentsView}
                                onChange={(e) => switchView(e.target.checked)}
                            />
                        </div>
                        Components
                    </div>

                    {!isComponentsView && (
                        <>
                            <div className="my-3">
                                <Form.Control
                                    type="text"
                                    placeholder="Search by title, description, or ASIN"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                                {itemsLoading && (
                                    <div className="d-flex justify-content-center mt-4">
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {/* Item display */}
                            <Row className="mt-2 text-center">
                                {displayData.length === 0 && !itemsLoading && (
                                    <div className="d-flex justify-content-center mt-4">
                                        <p>No results found</p>
                                    </div>
                                )}

                                {displayData.map((item) => (
                                    <Col xs={12} md={6} lg={3} className="d-flex px-2 my-2" key={item.sk}>
                                        <Card className="position-relative h-100 d-flex flex-column">
                                            <Card.Body className="d-flex justify-content-center align-items-center px-4">
                                                <Link to={item.url}>
                                                    <img src={item.image_url ? item.image_url : '/images/default-image.jpg'}
                                                        onError={(e) => {
                                                            e.target.onerror = null; // Prevent infinite loop if fallback image fails
                                                            e.target.src = "/images/default-image.jpg";
                                                        }}
                                                        alt={item.title} className="rounded" style={{
                                                            maxHeight: '210px',
                                                            width: '100%',
                                                            objectFit: 'cover'
                                                        }} />
                                                </Link>
                                            </Card.Body>
                                            <Card.Body className="flex-grow-1 pt-0">
                                                <Link to={item.url} className="text-body text-decoration-none hover:text-primary">
                                                    <h5 className="w-100" title={item.title}>
                                                        {item.title.length > 20 ? `${item.title.substring(0, 20)}...` : item.title}
                                                    </h5>
                                                </Link>
                                                {item.asin && (
                                                    <h6 className="text-secondary"><strong>ASIN:</strong> {item.asin}</h6>
                                                )}
                                                <h6 className="text-success"><strong>Instock Quantity:</strong> {item.instock_quantity}</h6>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>

                            {paginationKey && searchQuery.length === 0 && (
                                <div className="row justify-content-center mt-4">
                                    <div className="col-auto">

                                        {itemsLoading ? (
                                            <button className="btn btn-primary" type="button" disabled="">
                                                <span
                                                    className="spinner-border spinner-border-sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            </button>
                                        ) : (
                                            <button onClick={() => getInstockListings()} className="btn btn-primary">
                                                Load More
                                            </button>
                                        )}
                                    </div>
                                </div>
                            )}
                        </>
                    )}

                    {isComponentsView && (
                        <>
                            <div className="my-3">
                                <Form.Control
                                    type="text"
                                    placeholder="Search by title or description"
                                    value={searchComponentQuery}
                                    onChange={(e) => setSearchComponentQuery(e.target.value)}
                                />
                                {componentsLoading && (
                                    <div className="d-flex justify-content-center mt-4">
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {/* Item display */}
                            <Row className="mt-2 text-center">
                                {componentDisplayData.length === 0 && !componentsLoading && (
                                    <div className="d-flex justify-content-center mt-4">
                                        <p>No results found</p>
                                    </div>
                                )}

                                {componentDisplayData.map((item) => (
                                    <Col xs={12} md={6} lg={3} className="d-flex px-2 my-2" key={item.sk}>
                                        <Card className="position-relative h-100 d-flex flex-column">
                                            <Card.Body className="d-flex justify-content-center align-items-center px-4">
                                                <Link to={item.url}>
                                                    <img src={item.image_url ? item.image_url : '/images/default-image.jpg'}
                                                        onError={(e) => {
                                                            e.target.onerror = null; // Prevent infinite loop if fallback image fails
                                                            e.target.src = "/images/default-image.jpg";
                                                        }}
                                                        alt={item.title} className="rounded" style={{
                                                            maxHeight: '210px',
                                                            width: '100%',
                                                            objectFit: 'cover'
                                                        }} />
                                                </Link>
                                            </Card.Body>
                                            <Card.Body className="flex-grow-1 pt-0">
                                                <Link to={item.url} className="text-body text-decoration-none hover:text-primary">
                                                    <h5 className="w-100" title={item.title}>
                                                        {item.title.length > 20 ? `${item.title.substring(0, 20)}...` : item.title}
                                                    </h5>
                                                </Link>
                                                <h6 className="text-success"><strong>Instock Quantity:</strong> {item.instock_quantity}</h6>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>

                            {componentsPaginationKey && searchComponentQuery.length === 0 && (
                                <div className="row justify-content-center mt-4">
                                    <div className="col-auto">

                                        {componentsLoading ? (
                                            <button className="btn btn-primary" type="button" disabled="">
                                                <span
                                                    className="spinner-border spinner-border-sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                            </button>
                                        ) : (
                                            <button onClick={() => getInstockComponents()} className="btn btn-primary">
                                                Load More
                                            </button>
                                        )}
                                    </div>
                                </div>
                            )}
                        </>
                    )}

                </div>
            </section>
        </>
    );
}

export default ViewInstockItems;