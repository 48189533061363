import { useRef, useState } from "react";
import axios from 'axios';
import { serverEndpoint } from "../../util/config";
import { CCol, CRow } from '@coreui/react';

function AddComponent() {
    const fileInputRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        instockQuantity: 'Select',
        componentImage: '',
        cog: 0
    });
    const [previewUrl, setPreviewUrl] = useState(null);

    const [errors, setErrors] = useState({});
    const [message, setMessage] = useState(null);

    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        if (formData.title.length === 0) {
            newErrors.title = 'Please provide a valid Title';
            isValid = false;
        }

        if (formData.instockQuantity === undefined || formData.instockQuantity === '') {
            newErrors.instockQuantity = "In-stock quantity is required.";
            isValid = false;
        } else if (!Number.isInteger(Number(formData.instockQuantity)) || Number(formData.instockQuantity) < 0) {
            newErrors.instockQuantity = "In-stock quantity must be a non-negative integer.";
            isValid = false;
        }

        if (formData.cog !== '') {
            if (isNaN(Number(formData.cog)) || Number(formData.cog) < 0) {
                newErrors.cog = "COG must be a non-negative number.";
                isValid = false;
            }
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            // Check file size (for example, 5MB limit)
            const maxSizeInBytes = 5 * 1024 * 1024;
            if (file.size > maxSizeInBytes) {
                setErrors({ ...errors, componentImage: 'File size should not exceed 5MB' });
                return;
            }

            // Clear previous errors if any
            setErrors({ ...errors, componentImage: null });

            // Set the file and create a preview URL
            setFormData({
                ...formData,
                componentImage: file,
            });

            // Generate and store the preview URL
            const preview = URL.createObjectURL(file);
            setPreviewUrl(preview);
        }
    };

    const handleSubmit = async (e) => {
        setMessage(null);
        e.preventDefault();
        if (validateForm()) {
            setLoading(true);

            try {
                let requestBody = {
                    title: formData.title,
                    description: formData.description === '' ? null : formData.description,
                    instock_quantity: formData.instockQuantity,
                    cog: formData.cog === '' ? 0 : formData.cog
                };
                
                if (formData.componentImage) {
                    setMessage('Uploading image...');
                    const uploadData = await axios.get(`${serverEndpoint}/images/get-upload-url/components`, {
                        params: {
                            contentType: formData.componentImage.type
                        },
                        withCredentials: true
                    });
    
                    const uploadUrl = uploadData.data.uploadURL;
                    
                    await axios.put(uploadUrl, formData.componentImage, {
                        headers: {
                            'Content-Type': formData.componentImage.type
                        }
                    })
                    requestBody.image_key = uploadData.data.key;
                }
                
                setMessage('Adding component...');
                await axios.post(`${serverEndpoint}/components/create-component`, requestBody, {
                    withCredentials: true
                });

                setMessage('Component Added');
                setFormData({
                    title: '',
                    description: '',
                    instockQuantity: 'Select',
                    componentImage: '',
                    cog: 0
                });
                if (fileInputRef.current) {
                    fileInputRef.current.value = '';
                }
                setPreviewUrl(null);
                setLoading(false);
            } catch (error) {
                console.error(error);
                setMessage(null);
                setErrors({ message: "Something went wrong, please try again!" });
                setLoading(false);
            }
        }
    };

    return (
        <div className="container p-3 rounded border bg-white">
            <CRow>
                <CCol xs={12}>
                    <div className="">
                        <h2>Add Component</h2>
                        <p className="text-secondary mb-2">Add items to your component library for re-use. 
                            <br/>
                            Note: Uploading larger images may result in longer component creation times due to required image processing.</p>
                    </div>
                    <hr className="text-muted" />

                    {errors.message && (
                        <div className="alert alert-danger" role="alert">
                            {errors.message}
                        </div>
                    )}

                    {message && (
                        <div className="alert alert-success" role="alert">
                            {message}
                        </div>
                    )}

                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label className="mb-2 text-muted" htmlFor="title">
                                Title
                            </label>
                            <input id="title" type="text" className={errors.title ? 'form-control is-invalid' : 'form-control'}
                                name="title" onChange={handleChange} value={formData.title} autoFocus
                            />
                            <div className="invalid-feedback">{errors?.title}</div>
                        </div>
                        <div className="mb-3">
                            <label className="mb-2 text-muted" htmlFor="description">
                                Description
                            </label>
                            <textarea
                                id="description"
                                name="description"
                                className={errors.description ? 'form-control is-invalid' : 'form-control'}
                                onChange={handleChange}
                                value={formData.description}
                                rows="4"
                            />
                            <div className="invalid-feedback">{errors?.description}</div>
                        </div>

                        <div className="mb-3">
                            <label className="mb-2 text-muted" htmlFor="instockQuantity">
                                Instock Quantity
                            </label>
                            <input id="instockQuantity" type="number" name="instockQuantity"
                                className={errors.instockQuantity ? 'form-control is-invalid' : 'form-control'}
                                onChange={handleChange} value={formData.instockQuantity}
                            />
                            <div className="invalid-feedback">{errors?.instockQuantity}</div>
                        </div>
                        <div className="mb-3">
                            <label className="mb-2 text-muted" htmlFor="cog">
                                COG (Cost of Goods)
                            </label>
                            <input id="cog" type="number" name="cog"
                                className={errors.cog ? 'form-control is-invalid' : 'form-control'}
                                onChange={handleChange} value={formData.cog}
                            />
                            <div className="invalid-feedback">{errors?.cog}</div>
                        </div>
                        <div className="mb-3">
                            <label className="mb-2 text-muted" htmlFor="componentImage">
                                Image
                            </label>
                            <input id="componentImage" type="file" name="componentImage"
                                className={errors.componentImage ? 'form-control is-invalid' : 'form-control'}
                                onChange={handleImageChange} accept="image/*" ref={fileInputRef}
                            />
                            <div className="invalid-feedback">{errors?.componentImage}</div>
                        </div>

                        {previewUrl && (
                            <div className="mt-3">
                                <p>Preview:</p>
                                <img src={previewUrl} alt="Preview" style={{ width: '200px', height: '200px', objectFit: 'cover' }} />
                            </div>
                        )}

                        <div className="align-items-center d-flex">
                            {loading && (
                                <button className="btn btn-primary ms-auto" type="button" disabled="">
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </button>
                            )}

                            {!loading && (
                                <button className="btn btn-primary ms-auto" type="submit">
                                    Add
                                </button>
                            )}
                        </div>
                    </form>
                </CCol>
            </CRow>
        </div>
    );
}

export default AddComponent;
